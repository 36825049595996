<main class="main__recaps" (scroll)="handleScroll($event)">
  <h1 class="main__title">RECAP FEED</h1>
  <div class="recaps__count">Total Recaps: {{ numRecapsTotal }}</div>
  <h3 *ngIf="err" class="error-message">{{err}}</h3>
  <section class="filters">
    <div class="filters__date-range">
      <div class="range__col-date-type">
        <select name="date_type" id="date_type" [(ngModel)]="selectedSortOrder" (change)="onSortOrderChange()">
          <option value="asc" selected>Ascending</option>
          <option value="desc">Descending</option>
        </select>
      </div>
      <div class="range__col">
        <div class="range__label"><label for="from_date" class="filter-sections--label">From</label></div>
        <input name="from_date" id="from_date" type="date" (change)="onFromDateChange($event)" [(ngModel)]="fromDate">
      </div>
      <div class="range__col">
        <div class="range__label"><label for="to_date">To</label></div>
        <input name="to_date" id="to_date" type="date" (change)="onToDateChange($event)" [(ngModel)]="toDate">
      </div>
    </div>
    <div class="filters__filters">
      <div class="filters__col">
        <div class="filters__label"><label for="status" class="filter-sections--label">Status</label></div>
        <select name="status" id="status" [(ngModel)]="status">
          <option value="need_attention" selected>Needing Attention</option>
          <option value="unapproved">Unapproved Shift</option>
          <option value="approved">Approved Shift</option>
          <option value="review">Under Review</option>
          <option value="ended">Unpublished Recap</option>
          <option value="finalized">Published Recap</option>
          <option value="good">Good Recap</option>
          <option value="bad">Bad Recap</option>
        </select>
      </div>

      <div class="filters__col">
        <div class="filters__label"><label for="game_type" class="filter-sections--label">Game Type</label></div>
        <select name="game_type" id="game_type" [(ngModel)]="gameType">
          <option value="" selected>All</option>
          <option value="trivia">Pub Quiz</option>
          <option value="slam">Survey Slam</option>
          <option value="bingo">Music Bingo</option>
          <option value="special">Special Event</option>
          <option value="private">Private Event</option>
        </select>
      </div>

      <div class="filters__col">
        <div class="typeahead__label">Kingdom</div>
        <select [(ngModel)]="kingdom">
          <option value="-1" selected>All</option>
          <option value="1">Kingdom 1</option>
          <option value="2">Kingdom 2</option>
          <option value="3">Kingdom 3</option>
          <option value="4">Kingdom 4</option>
          <option value="0">Unassigned</option>
        </select>

      </div>

    </div>
  </section>
  <section class="typeahead">
    <div class="filters__typeahead">
      <div class="typeahead__col">
        <div class="typeahead__label">Employee Name</div>
        <app-typeahead [itemTypeID]="'employee'" (itemSelected)="onTypeaheadSelected('employee', $event.sid)"
          (clearSelected)="onTypeaheadCleared('employee')"></app-typeahead>
      </div>
      <div class="typeahead__col">
        <div class="typeahead__label">Venue Name</div>
        <app-typeahead [itemTypeID]="'venue'" (itemSelected)="onTypeaheadSelected('venue', $event.sid)"
          (clearSelected)="onTypeaheadCleared('venue')"></app-typeahead>
      </div>
    </div>
  </section>
  <section class="filters__update"><button class="sm-btn-red" (click)="getFilteredRecaps()">Update</button></section>

  <section class="recaps-scroll">
    <ng-container *ngIf="!loading">
      <ng-container *ngFor="let r of recaps; let i = index">
        <app-recap *ngIf="i < numRecapsShown" [gameStub]="r" [user]="user" (loaded)="registerLoadedRecap()"></app-recap>
      </ng-container>
    </ng-container>
    <div *ngIf="loading || numRecapsLoaded < recaps.length" class="recaps__loading-message">
      <h2>Loading Recaps, please wait...</h2>
    </div>
    <div *ngIf="!loading && numRecapsLoaded >= recaps?.length" class="recaps__loading-message">
      <h2>No more recaps to show.</h2>
    </div>
  </section>
</main>
