<h2 class="center-text">Wrong Answers Report</h2>
<div *ngIf="set_id; else chooseSet" class="hrz-start center-align-items">
	<h3>Viewing set #{{set_id}}</h3>
	<button *ngIf="set_id" (click)="clearSet()" class="std-ml sm-btn-red">Change Set</button>
</div>
<p *ngIf="err">{{err}}</p>
<ng-container *ngIf="reportData; else loadingMsg">
	<app-wrong-answer-round *ngFor="let r of reportData.rounds" [round]="r"></app-wrong-answer-round>
</ng-container>

<ng-template #chooseSet>
	<div class="wrong-answer--set-select flex items-center w-100 justify-center">
		<input #sidInput type="number" placeholder="Enter a set id...">
		<button (click)="lookupSet(sidInput.value)" class="sm-btn-red">Find Set</button>
	</div>
</ng-template>

<ng-template #loadingMsg>
	<h3 *ngIf="set_id">Loading Report...</h3>
</ng-template>
