<h1 class="survey--title">
	<span>Survey Management</span>
	<button (click)="startNew()" class="basic-btn-red">+ New</button>
</h1>

<div *ngIf="loading; else surveyFilters">
	<h2 class="center-text">Fetching Surveys</h2>
</div>



<ng-template #surveyFilters>
	<div class="survey--control-bar-container">
		<div class="std-p survey--control-bar">
			<div class="survey--filter-type">
				<label>Filter By:</label>
				<select (change)="switchView($event.target.value)">
					<option [selected]="view === 'status'" value="status">Status</option>
					<option [selected]="view === 'date'" value="date">Date</option>
					<option [selected]="view === 'search'" value="search">Search</option>
				</select>
			</div>
			<div *ngIf="view === 'status'" class="survey--status-box">
				<select (change)="changeStatus($event.target.value)">
					<option [selected]="status === 'all-unused'" value="all-unused">All Unused</option>
					<option [selected]="status === 'inactive'" value="inactive">Inactive</option>
					<option [selected]="status === 'active'" value="active">Active</option>
					<option [selected]="status === 'completed'" value="completed">Completed</option>
					<option [selected]="status === 'finalized'" value="finalized">Finalized</option>
				</select>
			</div>
			<div *ngIf="view === 'date'" class="survey--date-range">
				<select (change)="dateType = $event.target.value">
					<option [selected]="dateType === 'created'" value="created">Created</option>
					<option [selected]="dateType === 'active_starting'" value="active_starting">Started</option>
					<option [selected]="dateType === 'completed'" value="completed">Completed</option>
					<option [selected]="dateType === 'finalized'" value="finalized">Finalized</option>
					<option [selected]="dateType === 'used'" value="used">Used</option>
				</select>
				<div class="survey--date-range-inputs">
					<div class="w-100">
						<label>between</label>
						<input [(ngModel)]="fromDate" type="date">
					</div>
					<div class="w-100">
						<label>and</label>
						<input [(ngModel)]="toDate" type="date">
					</div>
				</div>
			</div>
			<div *ngIf="view === 'search'" class="survey--search-bar">
				<input type="text" (input)="updateSearch($event.target.value)" placeholder="Search...">
			</div>
			<div *ngIf="view === 'date'" class="survey--update-button">
				<button class="basic-btn-red" (click)="fetchSurveys()" [disabled]="view === 'date' && !fromDate && !toDate">Update</button>
			</div>
		</div>
		<div class="survey--download-control md-pb">
			<label class="md-mr">Download: </label>
			<div class="survey--download-buttons-container">
				<button class="basic-btn-lgt-blue" (click)="downloadSelected()">Selected</button>
				<button class="basic-btn-lgt-blue" (click)="downloadView()">View</button>
			</div>
		</div>
	</div>
	<h2 *ngIf="err" class="error-message">{{err}}</h2>
	<div *ngIf="!loadingSearch">
		<div *ngIf="surveys && surveys.length; else noSurveys" class="survey--survey-list">
			<app-survey 
			class="survey--survey-component"
			*ngFor="let s of surveys | searchByProp:'question_text':search; let i = index"
			[survey]="s"
			(deleted)="removeFromList($event)"></app-survey>
			<div class="bumper"></div>
		</div>
	</div>
</ng-template>

<ng-template #noSurveys>
	<div class="survey--no-survey">
		<h2 class="center-text">No Surveys match that criteria.</h2>
	</div>
</ng-template>

<div *ngIf="activeModal === 'new'" class="modal-background">
	<div class="click-layer" (click)="cancelNew()"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>New Survey</h2>
			<h2 class="no-m sm-px" (click)="cancelNew()">X</h2>
		</div>
		<app-survey-new (createNew)="createNew()" (cancelNew)="cancelNew()"></app-survey-new>
	</div>
</div>
