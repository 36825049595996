import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-down-arrow',
  templateUrl: './svg-down-arrow.component.html',
  styleUrls: ['./svg-down-arrow.component.css']
})
export class SvgDownArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
