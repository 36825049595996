import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-add',
  templateUrl: './svg-add.component.html',
  styleUrls: ['./svg-add.component.css']
})
export class SvgAddComponent implements OnInit {

	@Input() fill: string = "#FFF";

	constructor() { }

	ngOnInit(): void {
	}

}
