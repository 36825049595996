import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
})
export class AuthComponent implements OnInit {
  @Output() login: EventEmitter<any> = new EventEmitter();

  username: string;
  password: string;
  honeypot: string;

  loading: boolean;
  err = "";

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {}

  submitCredentials() {
    this.err = "";
    if (!this.username) {
      this.err = "Please enter your email address!";
      return;
    }
    if (!this.password) {
      this.err = "Please enter your password!";
      return;
    }
    this.loading = true;
    let obs = this.auth.getUserToken(this.username, this.password, this.honeypot).subscribe(
      (res: any) => {

        if (res?.status === "set-password") {
          this.err = res?.message;
        }

        if (res?.access_token) {
          this.auth.setToken(res.access_token);
          this.auth.getUserDetail().subscribe((user: User) => {
            this.login.emit();
            this.loading = false;
            this.auth.setUser(user);

            // get the intended url
            const intendedUrl = sessionStorage.getItem("intended_url");
            if (intendedUrl) {
              sessionStorage.removeItem("intended_url");
              this.router.navigateByUrl(intendedUrl);
            } else {
              this.router.navigate([""]);
            }
          });
        }
      },
      (err) => {
        this.err = err.error.detail;
        this.loading = false;
      }
    );
  }
}
