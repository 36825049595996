<div class="auth-container">
  <div class="auth-box">
    <form  (submit)="requestReset()" [formGroup]="forgotPasswordForm">
      <h2 class="no-mt">Forgot Password</h2>
      <p *ngIf="errorMsg" class="error-message">{{ errorMsg }}</p>
      <p *ngIf="!errorMsg" class="text-success center-text">
        <span *ngIf="submitting">Please wait...</span>
        <span class="text-green-400" *ngIf="!submitting && this.successMsg"> {{ this.successMsg}}</span>
      </p>
      <div *ngIf="!this.successMsg">
        <input required  type="email" name="email" placeholder="Enter your email" formControlName="email">

      <input [disabled]="submitting" type="submit">
      </div>
      <p class="center-text"><a href="/login">Login</a></p>
    </form>
  </div>
</div>
