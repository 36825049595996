import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wrong-answer',
  templateUrl: './wrong-answer.component.html',
  styleUrls: ['./wrong-answer.component.css']
})
export class WrongAnswerComponent implements OnInit {

	set_id: number;

	reportData: WrongAnswerReport

	loading = true;

	err = "";

	constructor(
		private report: ReportsService, 
		private route: ActivatedRoute,
		private router: Router) { }

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.set_id = params['setid'];
			if (this.set_id) this.getWrongAnswers();
		})
	}

	getWrongAnswers() {
		this.err = "";
		this.report.getWrongAnswerReport(this.set_id)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: WrongAnswerReport) => {
					this.reportData = this.sortReport(res);
					console.log(res);
				},
				err => this.err = err.error.detail
			)
	}

	sortReport(report: WrongAnswerReport) {
		report.rounds.sort((a, b) => {
			if (a.round_order < b.round_order) return -1;
			else if (a.round_order > b.round_order) return 1;
			else return 0;
		})

		for (let r of report.rounds) {
			r.questions.sort((a, b) => {
				if (a.question_order < b.question_order) return -1;
				else if (a.question_order > b.question_order) return 1;
				else return 0;
			})

			for (let q of r.questions) {
				q.incorrect_answers.sort((a, b) => {
					if (a.times_used < b.times_used) return 1;
					else if (a.times_used > b.times_used) return -1;
					else return 0;
				})
			}
		}

		return report;
	}

	lookupSet(set_id: number) {
		if (set_id) this.router.navigate([`${set_id}`], {relativeTo: this.route});
	}

	clearSet() {
		this.router.navigate(["reports", "content", "wrong-answer"]);
	}

}
