<div class="menu-container">
	<div class="menu-background" (click)="close.emit(true)"></div>
	<div class="menu-box">
		<div class="close-box">
			<i (click)="emitClose()" class="material-icons">clear</i>
		</div>
		<div class="nav-list">
			<ul>
				<li (click)="emitNav([''])">Dashboard</li>
				<li (click)="emitNav(['/notifications'])">Notifications</li>
				<li (click)="emitNav(['/games/list'])">Search Games</li>
				<li (click)="emitNav(['/recaps'])">Recap Feed</li>
				<li (click)="emitNav(['/surveys'])">Surveys</li>
				<li (click)="emitNav(['/reports'])">Reports</li>
				<li (click)="emitLogout()">Log Out</li>
			</ul>
		</div>
		<div class="version-msg">
			<span>Version {{ver}}</span>
		</div>
	</div>
</div>