import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CSVService {

	constructor() { }

	export(data: any, fileName: string, type?: string) {
	    if (!type) { type = 'text/csv' }
	    let blob = new Blob([data], { type: type });
	    let url = window.URL.createObjectURL(blob);
	    if (navigator.msSaveOrOpenBlob) {
	        navigator.msSaveBlob(blob, fileName);
	    } else {
	        let a = document.createElement('a');
	        a.href = url;
	        a.download = fileName;
	        document.body.appendChild(a);
	        a.click();        
	        document.body.removeChild(a);
	    }
	    window.URL.revokeObjectURL(url);
	}
}
