import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeahead'
})
export class TypeaheadPipe implements PipeTransform {

  transform(value: { name: string, sid: string }[], searchTerm: string = ""): { name: string, sid: string }[] {
    let newSearchTerms: string[] = searchTerm.replace("'", "").replace(".", "").split(" ");
    
    const filteredData = value.filter(item => {
      let isMatched = true;
      newSearchTerms.forEach(term => {
        if (!item.name.toLowerCase().includes(term.toLowerCase())) {
          isMatched = false;
        }
      })
      return isMatched;
    });

    return filteredData.slice(0, 10);
  }
}
