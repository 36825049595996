import { Component, OnInit, Input } from '@angular/core';
import { SurveyService } from '../services/survey.service';

@Component({
  selector: 'app-wrong-answer-question',
  templateUrl: './wrong-answer-question.component.html',
  styleUrls: ['./wrong-answer-question.component.css']
})
export class WrongAnswerQuestionComponent implements OnInit {

	@Input() question: WrongAnswerQuestion;

	loading: boolean = true;
	err: string = "";

	modal: string = "";

	refContext: any = { addSurvey: this.addSurvey.bind(this) };

	newSurveyTemplate: NewSurvey;

	constructor(private surveyServ: SurveyService) { }

	ngOnInit(): void {
		if (!this.question.survey) this.getRelatedSurvey();
		this.newSurveyTemplate = this.makeNewSurvey();
	}

	getRelatedSurvey() {
		this.err = "";
		if (this.question) {
			this.surveyServ.getByImportId(this.question.question_id)
				.subscribe(
					(res: {survey: Survey}) => {
						this.question.survey = res.survey;
						this.loading = false;
					},
					err => this.err = err.error.detail)
		}
	}

	makeNewSurvey() {
		let question_text = `We asked, "${this.question.question_text}"`;
		question_text = `${question_text} The correct answer was "${this.question.correct_answers[0]}".`;
		question_text = `${question_text} What was the most popular wrong answer?`;
		let cdate = new Date().toISOString().substring(0, 19);
		let completed = `${cdate}+00:00`
		return {
			question_text,
			completed,
			responses: [],
			response_target: 100,
			options: [],
			import_id: this.question.question_id.toString(),
		}
	}

	addSurvey(survey: Survey) {
		this.question.survey = survey;
		this.modal = "";
	}

}
