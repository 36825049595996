import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-thumbs-down',
  templateUrl: './svg-thumbs-down.component.html',
  styleUrls: ['./svg-thumbs-down.component.css']
})
export class SvgThumbsDownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
