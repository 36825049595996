<div class="flex flex-col">
  <h2 class="center-text">Players by Venue</h2>
    <div class="flex items-center w-100 justify-center">

        <div class="flex flex-col">
            <label class="label">Venue</label>
            <app-typeahead [itemTypeID]="'venue'" (itemSelected)="onTypeaheadSelected($event)"></app-typeahead>
        </div>

        <div class="flex flex-col std-pl">
            <label for="from">Date From: </label>
            <input [(ngModel)]="date_from" type="date" name="from" id="from">
        </div>
        <div class="flex flex-col std-pl">
            <label for="to">Date To: </label>
            <input [(ngModel)]="date_to" type="date" name="to" id="to">
        </div>
        <div class="flex flex-col">
            <span>&nbsp;</span>
            <button class="sm-btn-red std-ml btn-blue" [disabled]="fetching" (click)="getPlayers()">{{ fetching ===
                true?
                'Searching...': 'Search' }}</button>
        </div>
    </div>

    <table *ngIf="players && !fetching" id="reports-table">
        <caption>
            <button (click)="exportTableToCSV('reports-table')" class="flex items-center basic-btn-white">Export <svg
                    width="24px" height="24px" class="md-ml" viewBox="-4 0 64 64" xmlns="http://www.w3.org/2000/svg"
                    fill="#000000">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z"
                            fill-rule="evenodd" clip-rule="evenodd" fill="#45B058"></path>
                        <path
                            d="M20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254l-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z"
                            fill="#ffffff"></path>
                        <g fill-rule="evenodd" clip-rule="evenodd">
                            <path d="M56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707h12.925z"
                                fill="#349C42"></path>
                            <path d="M37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z"
                                opacity=".5" fill="#ffffff"></path>
                        </g>
                    </g>
                </svg></button>
        </caption>
        <thead>
            <tr>
                <th>No.</th>
                <th>Player Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Games Played</th>
                <th>Teams Played</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="players.players.length > 0 else noRecord">
                <tr *ngFor="let player of players.players; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{ player.name }}</td>
                    <td>{{ player.email }}</td>
                    <td>{{ player.phone }}</td>
                    <td>{{ player.played_games }}</td>
                    <td>{{ player.teams.join(", ") }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <p class="center-text xl-mt" *ngIf="fetching">Generating Report...</p>

</div>

<ng-template #noRecord>
    <tr>
        <td colspan="6" class="center-text">No Record Found</td>
    </tr>
</ng-template>
