import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.css']
})
export class CircularProgressBarComponent implements OnInit {
  @Input() speed: number = 10
  @Input() initialValue: number = 0
  @Input() finalValue: number
  @Input() topLabel: string = ''
  @Input() bottomLabel: string = ''

  @ViewChild('circularBar') circularBar: ElementRef
  @ViewChild('percentValue') percentValue: ElementRef

  constructor() { }

  ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    let circularBar = this.circularBar.nativeElement as HTMLElement;
    let percentValue = this.percentValue.nativeElement as HTMLElement;
    let progressColor = "#EEEEEE";

    switch (true) {
      case this.finalValue <= 75:
        progressColor = "#11C543";
        break;
      case this.finalValue <= 88:
        progressColor = "#FCD240";
        break;
      case this.finalValue <= 100:
        progressColor = "#FF6363";
        break;
      default:
        progressColor = "#EEEEEE";
        break;
    }

    let timer = setInterval(() => {
      this.initialValue += 1;

      circularBar.style.background = `conic-gradient(${progressColor} ${this.initialValue / 100 * 360}deg, #EEEEEE 0deg)`;
      percentValue.innerHTML = this.initialValue + "%";
      percentValue.style.color = progressColor

      if (this.initialValue >= this.finalValue) {
        clearInterval(timer);
      }
    }, this.speed)
  }

}
