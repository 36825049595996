<div class="notes__col-writer">
  <span class="bold">{{ noteAddedDate }}, {{ noteAddedTime }}: </span>
  {{ notes.display_name }}
</div>
<div class="notes__col-note">
  <span *ngIf="editNoteBool !== true">{{ notes.content }}</span>
  <textarea class="notes__edit-input"
    *ngIf="editNoteBool === true" 
    [(ngModel)]="officeNoteEdit" 
    [disabled]="notesLoading === true"
  >
  </textarea>
  <div class="notes__icons">
    <app-svg-edit 
      class="notes__edit-icon" 
      *ngIf="!editNoteBool && user.userid === notes.userid"
      (click)="activateNoteEdit(notes.content)" 
      [class.disabled]="notesLoading"></app-svg-edit>
    <app-svg-trash 
      class="notes__delete-icon"
      *ngIf="!editNoteBool && user.userid === notes.userid"
      (click)="deleteOfficeNote(notes.note_code)" 
      [class.disabled]="notesLoading"></app-svg-trash>
    <app-svg-save
      class="notes__save-icon"
      *ngIf="editNoteBool"
      (click)="editOfficeNote(notes.note_code)" 
      [class.disabled]="notesLoading"></app-svg-save>
    <app-svg-cancel
      class="notes__close-icon"
      *ngIf="editNoteBool"
      (click)="deactivateNoteEdit()" 
      [class.disabled]="notesLoading"></app-svg-cancel>
  </div>
</div>