import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NotificationService } from "../services/notification.service";
import { catchError, finalize } from "rxjs/operators";
import { throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  @ViewChild("messageElem") messageElem: ElementRef;

  today: Date = new Date();
  fetchingNotifs: boolean = false;
  notifications: AdminNotification[];
  notification: AdminNotification;
  notificationOriginal: AdminNotification;
  notificationIndex: number;

  dateFrom: Date = null;
  dateTo: Date = null;
  filterBy: any = "";
  filterActive: boolean = false;

  showFilter: boolean = false;
  showManageModal: boolean = false;
  editorMode: boolean = false;
  updating: boolean = false;
  saving: boolean = false;
  confirmingDelete: boolean = false;
  deleting: boolean = false;
  fetchingUsers: boolean = false;
  fetchingSeeners: boolean = false;
  errorMessage: string = "";

  userOnExceptions: UserNameAndId[] = [];
  notificationSeenBy: UserNameAndId[] = [];

  sendMailToQM: boolean = false;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    /// Subscribe to notifications
    this.notificationService.notifications$.subscribe((notifs) => {
      this.notifications = notifs;
    });
    /// Subscribe to selected notification
    this.notificationService.notificationIndex$.subscribe((value) => {
      // Show modal
      if (value != null) {
        this.showManageModal = true;
      }

      this.initNotifications(value);
    });

    this.fetchMonthAgo();
  }
  /***
   * Initialize the selected notification
   *
   * `this.notification` will act as the Model
   * while
   * `this.notificationOriginal` will hold the initial value of notification
   */
  initNotifications(index: number) {
    this.notificationIndex = index;
    this.notificationOriginal = this.notifications[index];

    if (this.notificationOriginal != undefined) {
      // Clone the initial value of notification
      this.notification = JSON.parse(JSON.stringify(this.notificationOriginal));

      /// Format Distribute Starting
      if (this.notification.distribute_starting.trim() !== "") {
        let d = new Date(this.notification.distribute_starting);
        this.notification.distribute_starting = d.toISOString().slice(0, 10);
      }
      /// Format Distribute Until
      if (this.notification.distribute_until.trim() !== "") {
        let d = new Date(this.notification.distribute_until);
        this.notification.distribute_until = d.toISOString().slice(0, 10);
      }

      // fetch names on exceptions
      this.fetchUsers(this.notification.exceptions, true);
    }
  }

  showDateFilter() {
    this.showFilter = true;
  }

  clearFilter() {
    this.dateFrom = null;
    this.dateTo = null;
    this.filterBy = "";
    this.filterActive = false;
    this.fetchMonthAgo();
  }

  executeFilter() {
    const div = this.messageElem.nativeElement;
    div.setAttribute("class", "error-message-container");

    if (this.filterBy == "") {
      div.textContent = "Please select a filter.";
      return;
    }

    if (
      this.dateFrom > this.dateTo ||
      this.dateFrom === null ||
      this.dateTo === null
    ) {
      div.textContent = "Invalid date filter.";
      return;
    }

    div.textContent = "";
    div.setAttribute("class", "");

    // hide modal
    this.showFilter = false;

    const from = new Date(this.dateFrom);
    const to = new Date(this.dateTo);
    // fetch notifications
    this.fetchByDate(this.filterBy, from, to);
    this.filterActive = true;
  }

  composeNotification() {
    // create an empty notification model
    let newNotification: AdminNotification = {
      notificationid: null,
      title: "",
      body: "",
      priority: false,
      distribute_starting: "",
      distribute_until: "",
      distribute_to_all: true,
      exceptions: [],
      created_by: null,
      created_on: new Date(),
      send_email: false,
    };

    this.notification = newNotification;
    // show modal.
    // set index as -1
    this.notificationService.showModal(-1);
  }

  saveNotification() {
    this.errorMessage = "";

    if (this.notification.title.trim() === "") {
      this.errorMessage = "Title is required!";
      return;
    }

    if (
      this.notification.distribute_to_all.toString().trim() === "false" &&
      this.userOnExceptions.length <= 0
    ) {
      this.errorMessage =
        "Please ensure to enter a recipient when sending to a specific user.";
      return;
    }

    // assign the exceptions
    this.userOnExceptions.forEach((element) => {
      this.notification.exceptions.push(element.userid);
    });
    // set notification id to empty string
    this.notification.notificationid = "";

    this.saving = true;
    this.notificationService
      .saveNotification(this.notification)
      .pipe(
        finalize(() => (this.saving = false)),
        catchError((error: HttpErrorResponse) => {
          this.saving = false;
          this.errorMessage = error?.error?.detail ?? error?.message;
          return throwError(error);
        })
      )
      .subscribe((response: AdminNotification) => {
        this.notificationService.addOne(response);
        //
        this.showManageModal = false;
      });
  }

  /***
   * Get all list of notifications within 1 month
   */
  fetchByDate(filter: string, from: Date, to: Date) {
    this.fetchingNotifs = true;

    this.notificationService
      .getByDate(filter, from.toISOString(), to.toISOString())
      .pipe(finalize(() => (this.fetchingNotifs = false)))
      .subscribe((response: AdminNotification[]) =>
        this.notificationService.populateNotifications(response)
      );
  }

  fetchMonthAgo() {
    /// List notification within 1 month

    let month_ago = new Date(this.today);
    month_ago.setMonth(month_ago.getMonth() - 1);

    if (this.today.getMonth() === 0) {
      month_ago.setFullYear(this.today.getFullYear() - 1);
    }
    this.fetchByDate("created_on", month_ago, this.today);
  }

  fetchUsers(
    ids: string[],
    userOnExceptions: boolean = false,
  ) {
    this.userOnExceptions = [];
    this.notificationSeenBy = [];
    if (ids.length <= 0) {
      return;
    }
    this.fetchingUsers = true;
    this.fetchingSeeners = true;
    // fetch recipients
    this.notificationService
      .getUsersById(ids)
      .pipe(finalize(() => (this.fetchingUsers = false)))
      .subscribe((response: UserNameAndId[]) => {
        if (userOnExceptions) {
          this.userOnExceptions = response;
        }
      });

    // fetch seeners
    this.notificationService
      .getSeener(this.notification.notificationid)
      .pipe(finalize(() => this.fetchingSeeners = false))
      .subscribe((seenby) => {
        this.notificationSeenBy = seenby
      });
  }

  onUserSelect(user: any) {
    this.userOnExceptions.push({ name: user.name, userid: user.sid });
  }

  removeUserFromException(index: number) {
    if (index > -1) {
      this.userOnExceptions.splice(index, 1);
    }
  }

  /**
   * Toggles the editor mode of notification modal
   */
  toggleEditorMode() {
    this.editorMode = !this.editorMode;

    if (this.editorMode === false) {
      this.resetNotificationValues();
    }
  }
  /**
   * Reset the value of notification model
   */
  resetNotificationValues() {
    this.notification = this.notificationOriginal;
    this.errorMessage = "";
    this.userOnExceptions = [];
  }
  /***
   * Update the notification
   */
  updateNotification() {
    if (this.notification.title.trim() === "") {
      this.errorMessage = "Title is required!";
      return;
    }

    if (
      this.notification.distribute_to_all.toString().trim() === "false" &&
      this.userOnExceptions.length <= 0
    ) {
      this.errorMessage =
        "Please ensure to enter a recipient when sending to a specific user.";
      return;
    }

    // assign the exceptions
    this.userOnExceptions.forEach((element) => {
      this.notification.exceptions.push(element.userid);
    });

    this.updating = true;

    this.notificationService
      .updateNotification(this.notification)
      .pipe(
        finalize(() => {
          this.updating = false;
          this.editorMode = false;
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorMessage = error?.error?.detail ?? error?.message;
          this.confirmingDelete = false;
          return throwError(error);
        })
      )
      .subscribe((response: AdminNotification) => {
        // Refresh the notifications
        this.notificationOriginal = response;
        this.notifications[this.notificationIndex] = response;
        this.notificationService.populateNotifications(this.notifications);
      });
  }
  /***
   * Delete a notification
   */
  deleteNotification() {
    this.deleting = true;
    this.notificationService
      .deleteNotification(this.notification.notificationid)
      .pipe(
        finalize(() => (this.deleting = false)),
        catchError((error: HttpErrorResponse) => {
          this.errorMessage = error?.statusText;
          this.confirmingDelete = false;
          return throwError(error);
        })
      )
      .subscribe((response: any) => {
        this.confirmingDelete = false;
        this.deleting = false;

        // Remove the deleted notification
        this.notifications.splice(this.notificationIndex, 1);
        // and refresh the notifications list
        this.notificationService.populateNotifications(this.notifications);

        // hide the modal
        this.showManageModal = false;
      });
  }
}
