import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.css']
})
export class CheckboxInputComponent implements OnInit {

	@Input() checked: boolean = false;
	@Input() label: string = "";

	@Output() checkChanged: EventEmitter<boolean> = new EventEmitter()

	constructor() { }

	ngOnInit(): void {
	}

	toggleChecked() {
		this.checked = !this.checked;
		this.checkChanged.emit(this.checked);
	}

}
