import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Route } from "@angular/router";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.css"],
})
export class NewPasswordComponent implements OnInit {
  submitting: boolean = false;
  errorMsg: string = "";
  successMsg: string = "";

  newPasswordForm: FormGroup;

  pass = "";

  correctlength = false;
  haslowercase = false;
  hasuppercase = false;
  hasnumber = false;
  spaces = true;
  hasspecial = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute
  ) {
    this.newPasswordForm = this.fb.group({
      password: ["", [Validators.required, Validators.minLength(8)]],
      passwordVerify: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  get password() {
    return this.newPasswordForm.get("password");
  }

  get passwordVerify() {
    return this.newPasswordForm.get("passwordVerify");
  }

  submitNewPassword() {
    this.errorMsg = "";

    if (this.password.value !== this.passwordVerify.value) {
      this.errorMsg = "Password didn't match.";
      return;
    }

    if (!this.correctlength || !this.haslowercase || !this.hasuppercase || !this.hasnumber || !this.hasspecial || this.spaces) {
      this.errorMsg = "Password does not fit requirements.";
      return;
    }

    if (this.newPasswordForm.valid) {
      this.submitting = true;
      this.auth.saveNewPassword(this.activeRoute.snapshot?.params?.token,this.password.value).subscribe(
        (response: any) => {
          this.successMsg =
            response?.message ?? "A link has been sent to your email";
          this.submitting = false;
        },
        (err) => {
          console.log("err => ", err);
          this.submitting = false;
          this.errorMsg = err?.error?.detail ?? "An error occured.";
        }
      );
    } else {
      if (this.password.value.length < 8) {
        this.errorMsg = "Password must be at least 8 characters.";
        return;
      }

      this.errorMsg = "An error occured.";
    }
  }

  checkPassword() {
    this.correctlength = (this.pass.length >= 8 && this.pass.length <= 24)
    this.haslowercase = /[a-z]/.test(this.pass)
    this.hasuppercase = /[A-Z]/.test(this.pass)
    this.hasnumber = /[0-9]/.test(this.pass)
    this.hasspecial = /[?!.()@#$%^&_\-+={[}\]|:;"'<,>./]/.test(this.pass)
    this.spaces = /\s/.test(this.pass)
  }
}
