<div class="image-carousel--container">
	<div class="list__reaction-container" *ngIf="displayImages.length !== 0">
		<app-svg-thumbs-up 
			*ngIf="!loading; else loadingImg"
			class="reaction--positive" 
			(click)="imageReaction('good')" 
			[ngClass]="{
				'reaction__disabled': images[currentIndex].rm_reaction === 'bad',
				'image-carousel__disabled': disableImgBtns && images[currentIndex].rm_reaction !== 'good',
				'reaction__active': images[currentIndex].rm_reaction === 'good'
			}"></app-svg-thumbs-up>
		<app-svg-thumbs-down 
			*ngIf="!loading; else loadingImg"
			class="reaction--negative" 
			(click)="images[currentIndex].rm_reaction === 'bad' ? imageReaction('bad') : isRevModalOpen = true" 
			[ngClass]="{
				'reaction__disabled': images[currentIndex].rm_reaction === 'good',
				'image-carousel__disabled': disableImgBtns && images[currentIndex].rm_reaction !== 'bad',
				'reaction__active': images[currentIndex].rm_reaction === 'bad'
			}"></app-svg-thumbs-down>
	</div>
	<div *ngIf="displayImages.length; else noImage" class="image-carousel--image-queue">
		<div class="image-carousel--image-box" *ngFor="let i of displayImages; let idx = index">
			<img [ngClass]="i.orientation" [src]="i.url">
		</div>
	</div>
	<div *ngIf="displayImages.length" class="image-carousel--img-ctrl">
		<div (click)="decrementIdx()" [ngClass]="disableImgBtns ? 'image-carousel__disabled' : ''">
			<i class="material-icons">arrow_circle_left</i>
		</div>
		<div (click)="incrementIdx()" [ngClass]="disableImgBtns ? 'image-carousel__disabled' : ''">
			<i class="material-icons">arrow_circle_right</i>
		</div>
	</div>
</div>

<ng-template #noImage>
	<h3>No Images Selected</h3>
</ng-template>

<app-basic-modal 
	*ngIf="isRevModalOpen"
	[title]="'Do you want to remove this image from the recap, or leave it in?'"
	[template]="revMsg"
	[hideX]="true"
	[staticBackground]="true"
	[action]="''"
	[cancel]="''">
</app-basic-modal>

<ng-template #revMsg>
	<div class="images__modal">
		<div>
			<button (click)="removeImage()" class="sm-btn-red" [disabled]="loading">Remove</button>
	    	<button (click)="imageReaction('bad')" class="sm-btn-red m-5" [disabled]="loading">Keep</button>
	    </div>
	    <button (click)="isRevModalOpen = false" class="sm-btn-red m-5" [disabled]="loading">Cancel</button>
	</div>
</ng-template>

<ng-template #loadingImg>
	<app-svg-loading-light
		class="reaction--loading"></app-svg-loading-light>
</ng-template>