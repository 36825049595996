import { Component, OnInit } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.css']
})
export class GameListComponent implements OnInit {

  sortBy = 'date';
  asc = false;
  search = '';
  to: Date = new Date();
  from: Date = new Date();
  minFrom: string | null = null

  loading = true;
  err = '';

  games: GameStub[] = [];

  constructor(private recapServe: RecapService, private utils: UtilsService) { }

  ngOnInit(): void {
    this.from.setDate(this.from.getDate() - 14);
    this.to.setDate(this.to.getDate() + 1);
    this.getGameList(this.parseDateUTC(this.from), this.parseDateUTC(this.to));
  }

  // need a string in formay YYYY-MM-DD
  parseDateUTC(date: Date) {
    let day: number | string = date.getUTCDate();
    day = (day < 10) ? `0${day}` : day.toString();
    let month: number | string = date.getUTCMonth() + 1;
    month = (month < 10) ? `0${month}` : month.toString();
    return `${date.getUTCFullYear()}-${month}-${day}`;
  }

  updateDates(from: string, to: string) {
    const dateRange = this.utils.validateDateRange(from, to);
    if (dateRange !== "valid") {
      this.err = dateRange

    } else {
      this.from = new Date(from);
      this.to = new Date(to);
      this.loading = true;
      this.getGameList(this.parseDateUTC(this.from), this.parseDateUTC(this.to));
    }
  }

  getGameList(from: string, to: string) {
    this.loading = true;
    this.err = "";
    let obs = this.recapServe.listGames(from, to)
      .subscribe((res: GameStub[]) => {
        this.games = res;
        this.sortGames();
        this.loading = false;
      },
        err => {
          console.log(err);

          this.loading = false;
          this.err = err.error.detail ?? "An error occurred. If problem persists, contact your site administrator";
        })
  }

  sortGames(newSort?: string) {
    if (newSort) this.sortBy = newSort;
    this.games.sort((a, b) => {
      if (this.sortBy === 'hosts') return this.compareHosts(a, b, this.asc);
      else {
        if (a[this.sortBy] > b[this.sortBy]) return (this.asc) ? 1 : -1;
        if (a[this.sortBy] < b[this.sortBy]) return (this.asc) ? -1 : 1;
      }
      return 0;
    })
  }

  flipSort() {
    this.asc = !this.asc;
    this.sortGames();
  }

  compareHosts(a: GameStub, b: GameStub, asc: boolean) {
    if (!a.hosts || !a.hosts.length) {
      if (b.hosts && b.hosts.length) return (asc) ? 1 : -1;
      else return 0;
    }
    if (!b.hosts || !b.hosts.length) {
      if (a.hosts && a.hosts.length) return (asc) ? -1 : 1;
      else return 0;
    }
    if (a.hosts[0].name > b.hosts[0].name) return (asc) ? 1 : -1;
    if (a.hosts[0].name < b.hosts[0].name) return (asc) ? -1 : 1;
    return 0;
  }

}
