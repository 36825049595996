import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class RecapService {
	rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

  constructor(
    private http: HttpClient
  ) {}

  formatDate(timeZone: string, date: string): string {
    const date1 = new Date(date)
    return new Intl.DateTimeFormat(undefined, { timeZone: timeZone, year: 'numeric', month: 'long', day: 'numeric' }).format(date1);
  }

  formatTime(timeZone: string, date: string): string {
    const time = new Date(date);
    return new Intl.DateTimeFormat(undefined, { timeZone: timeZone, hour: 'numeric', minute: '2-digit' }).format(time);
  }

  convertTimeZoneToAbbreviation(timeZone: string) {
		const date = new Date();

		const timeString = new Intl.DateTimeFormat('en-US', {timeZone: timeZone, timeZoneName:'short'}).format(date);
		const abbreviation = timeString.split(' ')[1];
		return abbreviation;
  }

  getRecapData(gameId: string): Observable<any> {
    const url = this.rootUri + `/api/recap/${gameId}`;
    return this.http.get<any>(url);
  }

  toggleTimecardBonus(shiftId: string, bonus: boolean) {
  	return this.http.post(`${this.rootUri}/api/shift/${shiftId}/edit/as-admin`, {bonus});
  }

  toggleShowDidNotHappen(shiftId: string, showHapp: boolean) {
    return this.http.post(`${this.rootUri}/api/shift/${shiftId}/edit`, {no_show: showHapp});
  }

  changeTargetRate(shiftId: string, target_rate: number) {
  	return this.http.post(`${this.rootUri}/api/shift/${shiftId}/edit/as-admin`, {target_rate});
  }

  approveTimecard(shiftId: string, approve: boolean = true) {
  	return this.http.post(`${this.rootUri}/api/shift/${shiftId}/approval`, {approve});
  }

  requiresRevision(shiftId: string, feedback: string) {
    return this.http.post(`${this.rootUri}/api/shift/${shiftId}/review`, {feedback});
  }

  addOfficeNote(shiftId: string, note: string) {
    return this.http.post(`${this.rootUri}/api/shift/${shiftId}/note/new`, {note});
  }

  editOfficeNote(shiftId: string, noteCode: string, content: string) {
    return this.http.post(`${this.rootUri}/api/shift/${shiftId}/note/${noteCode}/edit`, {content});
  }

  deleteOfficeNote(shiftId: string, noteCode: string) {
    console.log(shiftId, noteCode);
    return this.http.post(`${this.rootUri}/api/shift/${shiftId}/note/${noteCode}/delete`, {});
  }

  blockImage(gameid: string, image_code: string, block_reason: string, block_player: boolean = false) {
		return this.http.post(`${this.rootUri}/api/recap/${gameid}/image/${image_code}/block`, {block_reason, block_player});
	}

  imageReaction(gameid: string, image_code: string, reaction: string) {
		return this.http.post(`${this.rootUri}/api/recap/${gameid}/image/${image_code}/reaction`, {reaction});
	}

	prepFile(file: File) {
		let data = new FormData();
		data.append('image', file);
		return data;
	}

	uploadImage(id: string, image: File, position: number = null) {
		let headers = new HttpHeaders({'enctype': 'multipart/form-data'});
		return this.http.post(
			`${this.rootUri}/api/recap/${id}/image/upload`,
			this.prepFile(image),
			{ 
				headers,
				params: {
					position: position.toString()
				}
			})
	}

	setImageApproval(gameid: string, image_code: string, approval: boolean, position: number = 0) {
		let pos = position !== null && position !== undefined ? position.toString() : "";
		return this.http.post(
			`${this.rootUri}/api/recap/${gameid}/image/${image_code}/approval`,
			{ approval, position: pos });
	}

	getGameState(gameid: string) {
		return this.http.get(this.rootUri + "/api/host/game/" + gameid + "/state");
	}

  publishRecap(gameid: string, game: RecapGameDataPublic) {
  	return this.http.post(`${this.rootUri}/api/recap/${gameid}/approval`, game);
  }

  isTimeDifferenceOverThreshold(exTime, shiftTime) {
    const date1 = new Date(exTime);
    const date2 = new Date(shiftTime);
    const expectDiffInMs = Math.abs(date1.getTime());
    const gameDiffInMs = Math.abs(date2.getTime());
    return gameDiffInMs > expectDiffInMs + 900000;
  }

  listGames(from: string, to: string) {
		return this.http.get(this.rootUri + "/api/admin/list-games-between", { params: {fromDate: from, toDate: to}});
	}
  
}