import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss']
})
export class RecapComponent implements OnInit {
  @Input() gameStub: GameIdWithDate;
  @Input() user: User;

  @Output() loaded: EventEmitter<boolean> = new EventEmitter();

  loading: boolean;
  err: string;
  publishBlock: boolean;
  hideRecap: boolean;
  hideTimecard: boolean;
  startTimeOver: boolean;
  tcApprovedBool: boolean;
  showTooltip: boolean = false;
  approvalLoading: boolean = false;

  recapGameData: RecapGameData;
  recapShiftsData: RecapShiftData[];
  shiftHostData: ShiftHost[];

  customPlayerCount: number = 0;
  customTeamsCount: number = 0;
  customEmptyCount: number = 0;
  customBlogContent: string = "";

  selectedTimeOption: string = '0';
  selectedPlayersOption: string = '0';
  selectedTeamsOption: string = '0';
  selectedEmptyOption: string = '0';
  selectedBlogOption: string = '0';

  gameDate: string;
  adjustedStart: string;
  adjustedEnd: string;
  gameStartTime: string;
  expStartTime: string;
  emptyTablesCount: number;
  playerCount: number;
  teamCount: number;
  timeEnded: string;
  timeStarted: string;
  utcFromTime: string = '12:00';
  utcToTime: string = '13:00';
  venueTz: string;
  recapVenueTz: string;

  revisionTarget: RecapShiftData;

  constructor(private recapService: RecapService) { }

  ngOnInit(): void {
    if (this.gameStub) {
      this.loadRecapData(this.gameStub.gameid);
    }
  }

  loadRecapData(gameId: string) {
    this.err = "";
    this.recapService.getRecapData(gameId)
      .subscribe((data: { game: RecapGameData, shifts: RecapShiftData[] }) => {
        // console.log(data.game);
        // console.log(data.shifts);
        this.resetGameData(data.game);
        this.resetShiftData(data.shifts);
        this.venueTz = this.recapService.convertTimeZoneToAbbreviation(data.game.venue_tz);

        this.loaded.emit();
      },
        err => {
          if (err.error.status_code === 404) this.err = "Recap Not found";
          else this.err = "Something went wrong. Please try again or contact your administrator.";
        });
  }

  resetGameData(game: RecapGameData) {
    this.recapGameData = game;
    this.recapVenueTz = this.recapService.convertTimeZoneToAbbreviation(this.recapGameData.venue_tz);

    const gameStart = this.recapGameData.first_action ? this.recapGameData.first_action : this.recapGameData.started;
    const gameEnd = this.recapGameData.last_action ? this.recapGameData.last_action : this.recapGameData.ended;
    this.recapGameData.game_start_formatted = this.recapService.formatTime(this.recapGameData.venue_tz, gameStart);
    if (this.recapGameData.status !== 'live') this.recapGameData.game_end_formatted = this.recapService.formatTime(this.recapGameData.venue_tz, gameEnd);
    this.gameDate = this.recapService.formatDate(this.recapGameData.venue_tz, this.recapGameData.date);
    if (this.recapGameData.status === 'finalized' && this.recapGameData.adjusted_start && this.recapGameData.adjusted_end) {
      this.adjustedStart = this.recapService.formatTime(this.recapGameData.venue_tz, this.recapGameData.adjusted_start);
      this.adjustedEnd = this.recapService.formatTime(this.recapGameData.venue_tz, this.recapGameData.adjusted_end);
    } else {
      this.adjustedStart = "";
      this.adjustedEnd = "";
    }
    this.playerCount = this.recapGameData.teams
      .filter(team => team.status === "joined")
      .reduce((accumulator, currentValue) => accumulator + currentValue.num_players, 0);
    this.teamCount = this.recapGameData.teams.filter(team => team.status === "joined").length;
    if (this.recapGameData.status !== 'live') this.recapGameData.scores.sort((a: GameScore, b: GameScore) => {
      if (a.score > b.score) return -1;
      else if (a.score < b.score) return 1;
      else return 0;
    })
  }

  resetShiftData(shifts: RecapShiftData[]) {
    this.recapShiftsData = shifts;

    for (let i = 0; i < this.recapShiftsData.length; i++) {
      this.recapShiftsData[i].qm_start_formatted = this.recapService.formatTime(this.recapGameData.venue_tz, this.recapShiftsData[i].game_start);
      if (this.recapGameData.status !== 'live') this.recapShiftsData[i].qm_end_formatted = this.recapService.formatTime(this.recapGameData.venue_tz, this.recapShiftsData[i].game_end);
      this.setGameFormattedTime(this.recapShiftsData[i].game_start)
    }
  }

  isAnyItemActive(): boolean {
    if (!this.recapShiftsData) return false;
    return this.recapShiftsData.some(item => item.no_show === false);
  }

  checkHours(selectedOption: string) {
    switch (selectedOption) {
      case "app":
        this.setGameFormattedTime(this.recapGameData.first_action ? this.recapGameData.first_action : this.recapGameData.started);
        break;
      default:
        for (let i = 0; i < this.recapShiftsData.length; i++) {
          this.setGameFormattedTime(this.recapShiftsData[i].game_start)
        }
        break;
    }
  }

  setGameFormattedTime(time: string) {
    this.gameStartTime = this.recapService.formatTime(this.recapGameData.venue_tz, time);
    this.expStartTime = this.recapService.formatTime(this.recapGameData.venue_tz, this.recapGameData.date);

    this.startTimeOver = this.recapService.isTimeDifferenceOverThreshold(this.recapGameData.date, time);
  }

  timecardApproved(timecard: RecapShiftData) {
    timecard.loading = true;
    this.hideTimecard = true;

    this.recapService.approveTimecard(timecard.shiftid, true)
      .pipe(finalize(() => timecard.loading = false))
      .subscribe((res: RecapShiftData) => {
        timecard.status = "approved";
      },
        err => {
          this.err =  err.error.detail;
          this.hideTimecard = false;
        })
  }

  timecardUnapproved(timecard: RecapShiftData) {
    timecard.loading = true;

    this.recapService.approveTimecard(timecard.shiftid, false)
      .pipe(finalize(() => timecard.loading = false))
      .subscribe((res: RecapShiftData) => {
        timecard.status = "submitted";
        this.hideTimecard = false;
      },
        err => {
          this.err =  err.error.detail;
        })
  }

  closeRevisionModal() {
    this.revisionTarget.blog_feedback = ""
    this.revisionTarget = undefined;
  }

  requiresRevision(timecard: RecapShiftData) {
    timecard.loading = true;
    this.revisionTarget = undefined;

    const index = this.recapShiftsData.findIndex((item) => item.shiftid == timecard.shiftid)
    this.recapShiftsData[index].blog_feedback = timecard.blog_feedback


    this.recapService.requiresRevision(timecard.shiftid, timecard.blog_feedback)
      .pipe(finalize(() => timecard.loading = false))
      .subscribe((res: RecapShiftData) => {
        timecard.status = res.status;

      },
        err => {
          this.err =  err.error.detail;
        })
  }

  publishRecap(approved: boolean) {
    let publicRecap: RecapGameDataPublic = {
      approved,
      adjusted_start: "",
      adjusted_end: ""
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedTimeOption === 'app':
        publicRecap.adjusted_start = this.recapGameData.started;
        this.adjustedStart = this.recapGameData.game_start_formatted;
        break;
      default:
        publicRecap.adjusted_start = this.recapShiftsData[parseInt(this.selectedTimeOption)].game_start;
        this.adjustedStart = this.recapShiftsData[parseInt(this.selectedTimeOption)].qm_start_formatted;
        break;
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedTimeOption === 'app':
        publicRecap.adjusted_end = this.recapGameData.ended;
        this.adjustedEnd = this.recapGameData.game_end_formatted;
        break;
      default:
        publicRecap.adjusted_end = this.recapShiftsData[parseInt(this.selectedTimeOption)].game_end;
        this.adjustedEnd = this.recapShiftsData[parseInt(this.selectedTimeOption)].qm_end_formatted;
        break;
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedPlayersOption === 'app':
        publicRecap.total_players = this.playerCount;
        break;
      case this.recapGameData.status === 'finalized' || this.selectedPlayersOption === 'custom':
        publicRecap.total_players = this.customPlayerCount;
        break;
      default:
        publicRecap.total_players = this.recapShiftsData[parseInt(this.selectedPlayersOption)].total_players;
        break;
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedTeamsOption === 'app':
        publicRecap.total_teams = this.teamCount;
        break;
      case this.recapGameData.status === 'finalized' || this.selectedTeamsOption === 'custom':
        publicRecap.total_teams = this.customTeamsCount;
        break;
      default:
        publicRecap.total_teams = this.recapShiftsData[parseInt(this.selectedTeamsOption)].qm_teams;
        break;
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedEmptyOption === 'custom':
        publicRecap.empty_tables = this.customEmptyCount;
        break;
      default:
        publicRecap.empty_tables = this.recapShiftsData[parseInt(this.selectedEmptyOption)].empty_tables;
        break;
    }
    switch (true) {
      case this.recapGameData.status === 'finalized' || this.selectedBlogOption === 'custom':
        publicRecap.blog = this.recapGameData.blog;
        break;
      default:
        publicRecap.blog = this.recapShiftsData[parseInt(this.selectedBlogOption)].recap;
        break;
    }
    this.loading = true;
    this.hideRecap = true;
    console.log(this.recapGameData.status === 'live')
    this.recapService.publishRecap(this.recapGameData.gameid, publicRecap)
      .pipe(finalize(() => this.loading = false))
      .subscribe((res: RecapGameData) => {
        this.resetGameData(res);
      },
        err => {
          this.hideRecap = false;
          this.err =  err.error.detail;
        })
  }

  timecardApprovedEvent(bool: boolean) {
    this.hideTimecard = bool;
  }

}
