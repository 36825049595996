import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators'

@Component({
	selector: 'app-recap-public',
	templateUrl: './recap-public.component.html',
	styleUrls: ['./recap-public.component.css']
})
export class RecapPublicComponent implements OnInit {

	@Input() game: RecapGameData;
	@Input() shifts: RecapShiftData[];
	@Input() user: User;

	@Output() blogUpdate: EventEmitter<string> = new EventEmitter();
	@Output() imgEditing: EventEmitter<boolean> = new EventEmitter();

	reactedImages: GameImage[] = [];
	approvedImages: GameImage[] = [];

	scoreEdit: boolean;
	gameAccessed: boolean;

	imageEdit: boolean;
	busyImages: boolean = false;

	blogChoice: string;

	loading: boolean;
	imgLoading: boolean;
	err: string = "";

	constructor(private recapServe: RecapService) { }

	ngOnInit(): void {
		this.setApproved();
		this.blogChoice = this.shifts.length ? '0' : 'custom';
	}

	addImages(imgs: GameImage[]) {
		for (let i of imgs) {
			this.game.images.push(i);
		}
		this.setApproved();
	}

	setApproved() {
		let imgs: GameImage[] = [];
		for (let i of this.game.images) {
			if (i.status === 'approved') {
				imgs.push(i);
			}
		}
		imgs.sort((a: GameImage, b: GameImage) => {
			if (a.position === undefined) {
				return b.position >= 0 ? 1 : 0;
			}
			else if (b.position === undefined) {
				return b.position >= 0 ? -1 : 0;
			}
			if (a.position > b.position) return 1;
			else if (a.position < b.position) return -1;
			else return 0;
		})
		this.approvedImages = imgs;
	}

	sortScores() {
		this.game.scores.sort((a: GameScore, b: GameScore) => {
			if (a.score > b.score) return -1;
			else if (a.score < b.score) return 1;
			else return 0;
		})
	}

	checkImagesForUpdate() {
		this.imgLoading = true;
		let toUpdate = [];
		for (let im of this.game.images) {
			if (im.pending_change) toUpdate.push(im);
		}
		this.updateImages(toUpdate);
	}

	startImgEdit() {
		this.imageEdit = true;
		this.imgEditing.emit(true);
	}

	startEditBlog() {
		if (this.blogChoice !== 'custom') {
			this.game.blog = this.shifts[parseInt(this.blogChoice)].recap;
			this.blogChoice = 'custom';
		}
	}

	updateImages(toUpdate: GameImage[]) {
		if (toUpdate.length) {
			let img = toUpdate.shift();
			if (img.status === 'blocked') {
				this.updateImages(toUpdate);
				return;
			}
			let approve = img.status === 'approved' ? true : false;
			this.recapServe.setImageApproval(
				this.game.gameid,
				img.image_code,
				approve,
				img.position)
				.subscribe(
					(res: GameImage[]) => {
						if (!toUpdate.length) {
							this.game.images = res;
							this.setApproved();
							this.imageEdit = false;
							this.imgEditing.emit(false);
							this.imgLoading = false;
						}
						else this.updateImages(toUpdate);
					},
					err => {
						this.err = err.error.detail;
						this.imgLoading = false;
					})
		}
		else {
			this.imageEdit = false;
			this.imgEditing.emit(false);
			this.imgLoading = false;
		}
	}

	doneScoreEdit() {
		this.gameAccessed = false;
		this.scoreEdit = false;
		this.loading = true;
		this.recapServe.getGameState(this.game.gameid)
			.pipe(finalize(() => this.loading = false))
			.subscribe((res: RecapGameData) => {
				this.game.scores = res.scores;
				this.game.scores.sort((a, b) => {
					if (a.score > b.score) return -1;
					else if (a.score < b.score) return 1;
					else return 0;
				})
				this.sortScores()
			},
				err => console.log(err.error.detail))
	}

	updateBlogChoice(event: string) {
		this.blogChoice = event;
		this.blogUpdate.emit(event);
	}

	getChildImages(reactedImages: GameImage[]) {
		let imgs: GameImage[] = [];

		for (let i of reactedImages) {
			if (i.status === 'approved') {
				imgs.push(i);
			}
		}
		imgs.sort((a: GameImage, b: GameImage) => {
			if (a.position === undefined) {
				return b.position >= 0 ? 1 : 0;
			}
			else if (b.position === undefined) {
				return b.position >= 0 ? -1 : 0;
			}
			if (a.position > b.position) return 1;
			else if (a.position < b.position) return -1;
			else return 0;
		})

		this.approvedImages = imgs;
	}

	sortedScoresByRank(): GameScore[] {
		this.game.scores.sort((a: GameScore, b: GameScore) => {
			if (a.rank_order > 0 && b.rank_order > 0) {
				return a.rank_order - b.rank_order;
			} else if (a.rank_order > 0) {
				return -1;
			} else if (b.rank_order > 0) {
				return 1;
			} else {
				return b.score - a.score;
			}
		});

		return this.game.scores;
	}
}
