import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	title = 'King Trivia Administrator';

	user: User;

	loading = true;
	userHover: boolean;

	params: any = {};

	err = "";

	constructor(
		private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router) { }

	ngOnInit() {
		this.route.params
			.subscribe(
				params => {
					let keys = Object.keys(params);
					if (keys.length) {
						for (let k of keys) {
							if (k === "ver") continue;
							else this.params[k] = params[k];
						}
					}
				})
		this.versionCheck()
		// let token = this.auth.getToken();
		// if (token) this.getUserInfo();
		// else this.loading = false;

		// listen for user changes
		this.auth.user$.subscribe((user) => {
			this.user = user
		})
	}

	getUserInfo() {
		let userObs = this.auth.getUserDetail()
			.subscribe((res: User) => {
				this.user = res;
				this.loading = false;
				this.auth.setUser(this.user)
			},
				err => {
					this.auth.setToken('');
					this.loading = false;
				})
	}

	versionCheck() {
		console.log("Checking version...")
		this.auth.getCurrentVersion()
			.subscribe(
				(res: string) => {
					let ver = localStorage.getItem('api_version')
					if (ver !== res) {
						localStorage.setItem('api_version', res)
						let p = ""
						let keys = Object.keys(this.params);
						for (let k of keys) {
							p = `${p}&${k}=${this.params[k]}`;
						}
						let loc = window.location
						window.location.href = `${loc.origin}${loc.pathname}?ver=${res}${p}`;
					}
					else {
						let token = this.auth.getToken();
						this.router.navigate(
							[],
							{
								relativeTo: this.route,
								queryParams: this.params
							})
						if (token) this.getUserInfo();
						else this.loading = false;
					}
				},
				err => this.err = err.error.detail)
	}

	removeUserInfo() {
		this.user = null;
		this.auth.setToken("");
		this.auth.clearUser()
		this.router.navigate(["/auth"])
	}

}
