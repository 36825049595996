<div *ngIf="!loading; else loadMsg" class="recap-public--container">
	<div *ngIf="!imageEdit; else imageList">
		<app-image-carousel (imgEvent)="getChildImages($event)" [images]="approvedImages" [gameData]="game"></app-image-carousel>
		<h3>{{approvedImages.length}} images added to recap. {{game.images.length}} images available.</h3>
		<div *ngIf="game.status !== 'finalized'" class="center-text">
			<button class="sm-btn-lgt-blue" (click)="startImgEdit()">Edit Images</button>
		</div>
	</div>
	<div class="recap-public--right-column">
		<div *ngIf="game.status !== 'finalized'; else blogDisplay" class="recap-public--choose-blog">
			<label class="recap__choose-blog">Choose Blog Content</label>
			<select (change)="updateBlogChoice($event.target.value)">
				<option *ngFor="let s of shifts; let i = index" [value]="i.toString()" selected="i === 0">{{s.user.name}}</option>
				<option value="custom">Custom</option>
			</select>
			<editor 
				*ngIf="blogChoice === 'custom'"
				[(ngModel)]="game.blog"
				apiKey="dwq4ypdrl015pq2mos8palrnjg0f2dujrq31o5fzwyvy0pml"
				[init]="{
					height: 300,
					menubar: false,
					plugins: 'link lists',
					toolbar: 'undo redo | bold italic underline blockquote strikethrough | alignleft aligncenter alignright | bullist numlist | link',
					link_context_toolbar: true
				}"></editor>
			<div *ngIf="blogChoice !== 'custom'" class="recap-public--blog-container">
				<div *ngIf="shifts[blogChoice].recap; else noBlog" [innerHTML]="shifts[blogChoice].recap | safeHtml" class="recap-public--display-blog"></div>
				<svg class="recap-public--edit-icon" 
			      *ngIf="game.status !== 'finalized'"
			      (click)="startEditBlog()" 
			      xmlns="http://www.w3.org/2000/svg" 
			      viewBox="0 0 512 512" 
			      height="24"
			    >
			      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
			    </svg>
			</div>
		</div>
		<div>
			<div class="recap-public--score-header">
				<div class="recap-public--score-rank">Rank</div>
				<div class="recap-public--score-teamid">Team #</div>
				<div class="recap-public--score-team-name">Team Name</div>
				<div class="recap-public--score">Score</div>
			</div>
			<div *ngFor="let s of sortedScoresByRank(); let i = index" class="recap-public--score-row">
				<div class="recap-public--score-rank">{{ s.rank_order > 0 ? s.rank_order : i+1 }}</div>
				<div *ngIf="s.team_id" class="recap-public--score-teamid"><a href="https://play.kingtrivia.com/team/{{s.team_id}}" target="_blank">#{{s.team_id}}</a></div>
				<div *ngIf="!s.team_id" class="recap-public--score-teamid">#______</div>
				<div class="recap-public--score-team-name">{{s.team_name_alternate}}</div>
				<div class="recap-public--score">{{s.score}}</div>
			</div>
			<div *ngIf="game.status !== 'finalized'" class="hrz-center std-mt">
				<button class="sm-btn-lgt-blue" (click)="scoreEdit = true">Edit Scores</button>
			</div>
		</div>
	</div>
</div>


<app-basic-modal 
	*ngIf="scoreEdit"
	[title]="'Edit Scores in New Tab'"
	[template]="editMsg"
	[hideX]="true"
	[staticBackground]="true"
	[action]="''"
	[cancel]="''">
	</app-basic-modal>

<ng-template #editMsg>
	<p>Scores can only be edited through the hosting interface. Use the button below to open the game in a new tab. When you are finished editing scores, come back here and click "Done Editing"</p>
	<div class="hrz-between">
		<button (click)="doneScoreEdit()" class="sm-btn-red">{{gameAccessed ? "Done Editing" : "Cancel"}}</button>
		<a href="https://host.kingtrivia.com/game/{{game.gameid}}/live" target="_blank">
			<button class="sm-btn-red" (click)="gameAccessed = true">Go to Game</button>
		</a>
	</div>
</ng-template>

<ng-template #imageList>
	<div *ngIf="!imgLoading; else loadMsg" class="recap-public--image-list">
		<app-image-list 
			[game]="game"
			[user]="user"
			[images]="game.images"
			(newImages)="addImages($event)"
			(setBusy)="busyImages = $event"></app-image-list>
		<div class="center-text">
			<button (click)="checkImagesForUpdate()" class="sm-btn-red std-mt">Done Editing</button>
		</div>
	</div>
</ng-template>

<ng-template #loadMsg>
	<h2>One Moment...</h2>
</ng-template>

<ng-template #noBlog>
	<div class="recap-public--display-blog">
		<p>[No blog written]</p>
	</div>
</ng-template>

<ng-template #blogDisplay>
	<div *ngIf="game.blog; else noBlog" [innerHTML]="game.blog | safeHtml" class="recap-public--display-blog"></div>
</ng-template>