<div class="auth-container">
  <div class="auth-box">
    <form  (submit)="submitNewPassword()" [formGroup]="newPasswordForm">
    <h2 class="no-mt">Set New Password</h2>
    <ul class="new-password--req-list">
      <li><i class="material-icons {{correctlength ? 'true' : 'false'}}-condition">{{correctlength ? "check" : "close"}}</i>Between 8 and 24 characters</li>
      <li><i class="material-icons {{hasuppercase ? 'true' : 'false'}}-condition">{{hasuppercase ? "check" : "close"}}</i>At least 1 uppercase letter</li>
      <li><i class="material-icons {{haslowercase ? 'true' : 'false'}}-condition">{{haslowercase ? "check" : "close"}}</i>At least 1 lowercase letter</li>
      <li><i class="material-icons {{hasnumber ? 'true' : 'false'}}-condition">{{hasnumber ? "check" : "close"}}</i>At least 1 number</li>
      <li><i class="material-icons {{hasspecial ? 'true' : 'false'}}-condition">{{hasspecial ? "check" : "close"}}</i>At least 1 special character: ()~!@#$%^&*_-+={{'{'}}[{{'}'}}]|:;"'<,>.?/</li>
      <li><i class="material-icons {{!spaces ? 'true' : 'false'}}-condition">{{!spaces ? "check" : "close"}}</i>No spaces</li>
    </ul>
    <p *ngIf="errorMsg" class="error-message">{{ errorMsg }}</p>
    <p *ngIf="!errorMsg" class="text-success center-text">
      <span *ngIf="submitting">Please wait...</span>
      <span class="text-green-400" *ngIf="!submitting && this.successMsg"> {{ this.successMsg}}</span>
    </p>
    <div *ngIf="!this.successMsg">
      <input required [(ngModel)]="pass" type="password" name="password" placeholder="Enter New Password" formControlName="password" (input)="checkPassword()">
      <input required  type="password" name="passwordVerify" placeholder="Verify New Password" formControlName="passwordVerify">

    <input [disabled]="submitting" type="submit">
    </div>
    <p class="center-text"><a href="/">Login</a></p>
  </form>
  </div>
</div>
