<div class="header-box">
	<div class="header-content">
		<img class="logo" src="assets/KTadmin.png">
		<div class="hamburger-box">
			<i (click)="menuOpen = true" class="material-icons">menu</i>
		</div>
	</div>
</div>

<app-main-menu *ngIf="menuOpen" [user]="user" (close)="closeMenu()" (nav)="navTo($event)"
	(logout)="activeModal = 'logoutConfirm'"></app-main-menu>

<div *ngIf="activeModal === 'logoutConfirm'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>Logout {{user.name}}?</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<div>
			<button class="basic-btn-lgt-blue" (click)="activeModal = ''">Go Back</button>
			<button class="basic-btn-red std-ml" (click)="logoutUser()">Logout</button>
		</div>
	</div>
</div>