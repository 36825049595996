<div class="notifications-header">
    <h1>Notifications</h1>
    <div>
        <button (click)="clearFilter()" *ngIf="filterActive" class="basic-btn-white"><i class="material-icons">clear</i>
            Clear Filter</button>
        <button (click)="showDateFilter()" class="basic-btn-green ml-10"><i class="material-icons">filter_alt</i>
            Filter</button>
        <button (click)="composeNotification()" class="basic-btn-blue  ml-10"><i class="material-icons">edit_square</i>
            Compose</button>
    </div>

</div>

<div *ngIf="!fetchingNotifs; else fetchingNotification" class="notifications-container">

    <ng-container *ngIf="notifications && notifications.length > 0; else noRecord">
        <app-notification *ngFor="let notification of notifications; last as isLast; index as index"
            [notification]="notification" [isLast]="isLast" [index]="index"></app-notification>
    </ng-container>

</div>

<!--- SKELETON LOADER --->
<ng-template #fetchingNotification>
    <div class="notifications-container">
        <app-skeleton-loader :repeat="5"></app-skeleton-loader>
    </div>
</ng-template>

<!-- NO RECORD FOUND -->
<ng-template #noRecord>
    <div class="flex w-100 h-100 items-center justify-center flex-col">
        <i class="material-icons text-xl text-muted">warning</i>
        <h4>No Record Found</h4>
    </div>
</ng-template>

<!-- DATE FILTER -->
<ng-template #filterModal>
    <div class="p-10">

        <table class="table">
            <tr>
                <td>Filter By</td>
                <td>Date From</td>
                <td>Date To</td>
            </tr>
            <tr>
                <td>
                    <select class="input-text text-xs border-1" [(ngModel)]="filterBy">
                        <option value="" disabled>Choose</option>
                        <option value="created_on">Created on</option>
                        <option value="distribute_starting">Distribute Starting</option>
                        <option value="distribute_suntil">Distribute Until</option>
                    </select>
                </td>
                <td>
                    <input class="input-text text-xs w-auto border-1" type="date" [(ngModel)]="dateFrom">
                </td>
                <td>
                    <input class="input-text text-xs w-auto border-1" type="date" [(ngModel)]="dateTo">
                </td>
            </tr>
        </table>
        <div class="mt-10" #messageElem></div>
    </div>
</ng-template>


<!-- MANAGE MODAL CONTENT -->
<ng-template #manageModalContent>

    <div class="flex items-center justify-between" *ngIf="notification.notificationid !== null">
        <button *ngIf="!confirmingDelete" class="plain-button notification-action-button button-theme-red"
            (click)="confirmingDelete = true">
            Delete notification
        </button>
        <div *ngIf="confirmingDelete" class="mt-neg-20" id="delete-confirmation-container">
            <div class="flex items-center">
                <h4 class=" text-danger">Are you sure you want to delete this notification?</h4>
                <button class="plain-button btn-sm ml-5" (click)="confirmingDelete = false">Cancel</button>
                <button [ngClass]="deleting ? 'text-white' : 'button-theme-red'" [disabled]="deleting"
                    class="plain-button  btn-sm ml-5" (click)="deleteNotification()">{{ deleting ?
                    'Deleting...':'Delete' }}</button>
            </div>
        </div>
        <button *ngIf="!confirmingDelete" class="plain-button notification-action-button" (click)="toggleEditorMode()">
            <i *ngIf="!editorMode" class="material-icons align-middle text-sm">edit</i>
            <i *ngIf="editorMode" class="material-icons align-middle text-sm">cancel</i>
            <span>{{ editorMode ? ' Cancel' : ' Edit' }}</span>
        </button>
    </div>
    <div class="p-10">
        <!-- TITLE -->
        <span class="label" *ngIf="notification.notificationid === null">Title</span>
        <input [disabled]="!editorMode && notification.notificationid != null" type="text"
            [(ngModel)]="notification.title"
            [ngClass]="editorMode ? 'editor-mode': notification.notificationid === null ?'input-border': ''"
            class="input-text">
        <!-- BODY-->
        <span class="label" *ngIf="notification.notificationid === null">Body</span>
        <textarea [disabled]="!editorMode && notification.notificationid != null" [(ngModel)]="notification.body"
            class="input-textarea"
            [ngClass]="editorMode ? 'editor-mode': notification.notificationid === null ?'input-border': ''" cols="30"
            rows="5"></textarea>
        <table class="table">
            <tr>
                <td class="label">Priority</td>
                <td *ngIf="!notification.notificationid" class="label">Send Email to QM</td>
            </tr>
            <tr>
                <!--- Priority --->
                <td>

                    <label class="switch">
                        <input [disabled]="!editorMode && notification.notificationid != null" type="checkbox"
                            [(ngModel)]="notification.priority">
                        <span class="slider round"></span>
                    </label>
                </td>
                <td *ngIf="!notification.notificationid">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="notification.send_email">
                        <span class="slider round"></span>
                    </label>

                </td>

            </tr>
            <tr>
                <td class="label">Send Starting On</td>
                <td class="label">Send Until</td>
            </tr>
            <tr>
                <!--- Distribute Starting --->
                <td><input [disabled]="!editorMode && notification.notificationid != null"
                        [ngClass]="editorMode ? 'editor-mode': notification.notificationid === null ?'input-border': ''"
                        type="date" class="input-text text-xs w-auto" [(ngModel)]="notification.distribute_starting">
                </td>
                <!--- Distribute Until --->
                <td><input [disabled]="!editorMode && notification.notificationid != null"
                        [ngClass]="editorMode ? 'editor-mode': notification.notificationid === null ?'input-border': ''"
                        type="date" class="input-text text-xs w-auto" [(ngModel)]="notification.distribute_until"></td>
            </tr>
            <tr>
                <td class="pt-20" *ngIf="editorMode || notification.notificationid === null; else distributionDetail">
                    <span class="label">Distribution Type</span>
                    <select class="w-100 sm-mb" [(ngModel)]="notification.distribute_to_all">
                        <option [value]="true">Send to all</option>
                        <option [value]="false">Send only to specified user(s)</option>
                    </select>
                    <br>
                    <span class="label">{{ notification.distribute_to_all.toString().trim() ==="true" ?
                        'Except:':
                        'Search User'
                        }}</span>
                    <app-typeahead [clearOnItemSelect]="true" [itemTypeID]="'employee'"
                        (itemSelected)="onUserSelect($event)"></app-typeahead>
                </td>
                <td class="text-left text-sm" colspan="2">

                    <span class="label" *ngIf="!fetchingUsers && userOnExceptions.length > 0">
                        {{ notification.distribute_to_all.toString().trim() ==="true" ? 'Except:': 'Specified User:'
                        }}
                    </span>

                    <small class="mt-20" *ngIf="userOnExceptions.length <=0 ; else displayUsers">{{ fetchingUsers ?
                        'Please wait...': '' }}</small>

                </td>
            </tr>

            <tr>
                <td colspan="3">
                    <hr>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf=" errorMessage.trim().length> 0" class="error-message-container">
        <span>{{errorMessage}}</span>
    </div>

    <ng-container [ngTemplateOutlet]="seeners"></ng-container>

</ng-template>

<!-- DISTRIBUTION MODE DETAIL -->
<ng-template #distributionDetail>
    <td>
        <span class="label">Distribution Type:</span>
        <strong class="pl-10 text-xs">{{ notification.distribute_to_all ? 'Send To All' : 'Send Only To' }}</strong>
    </td>
</ng-template>

<!--- USERS ON EXCEPTIONS --->
<ng-template #displayUsers>
    <ul class="mt-10">
        <li *ngFor="let user of userOnExceptions; index as index">
            <div class="flex items-center">
                <button (click)="removeUserFromException(index)" class="transparent-button text-danger"><i class=" material-icons
                    text-sm">remove_circle</i></button>
                <span>{{ user.name }}</span>
            </div>
        </li>
    </ul>
</ng-template>

<!-- LIST OF USERS WHO HAVE SEEN THE NOTIFICATION -->
<ng-template #seeners>
    <div *ngIf="notification.notificationid !== '' && notification.notificationid !== null">
        <span class="label">User's who have seen this notification</span>
        <ul *ngIf="notificationSeenBy.length > 0; else noSeeners">
            <li class="text-xs" *ngFor="let user of notificationSeenBy">{{ user.name }}</li>
        </ul>
    </div>
    <ng-template #noSeeners>
        <p class="text-xs">{{ fetchingSeeners ? 'Checking...' :'No one yet.' }}</p>
    </ng-template>
</ng-template>

<!-- MANAGE MODAL -->
<app-basic-modal *ngIf="showManageModal" [template]="manageModalContent" [hideX]="true" [staticBackground]="false"
    [title]="notification.notificationid !== null ? '':'Compose Notification'" [cancel]="'Close'"
    (doCancel)="showManageModal=false; editorMode=false; resetNotificationValues()"
    [action]=" editorMode && updating ? 'Updating...': notification.notificationid !== null && notification.notificationid !== '' ? 'Update' : saving ? 'Saving...' : 'Save' "
    (doAction)="editorMode ? updateNotification() : notification.notificationid === null && saving === false ? saveNotification() : null "
    [disabled]="updating">
</app-basic-modal>

<!-- FILTER MODAL -->
<app-basic-modal *ngIf="showFilter" [template]="filterModal" [hideX]="true" [staticBackground]="false"
    title="Date Filter" (doCancel)="showFilter = false" action="Submit" (doAction)="executeFilter()">

</app-basic-modal>
