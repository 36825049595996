import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {

  submitting: boolean = false
  errorMsg: string = ""
  successMsg: string = ""

  forgotPasswordForm: FormGroup;

  constructor(private auth: AuthService, private fb: FormBuilder) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }


  get email() {
    return this.forgotPasswordForm.get('email');
  }

  requestReset() {

    this.errorMsg = "";

    if (this.forgotPasswordForm.valid) {
      this.submitting = true;
      this.auth.requestResetPassword(this.email.value)
        .subscribe(
          (response: any) => {
            if( response?.status === 'success' ){
              this.successMsg = response?.message ?? 'A password reset link has been sent to your email.';
            }
            this.submitting = false;
          },
          (err) => {
            console.log("err => ", err);
            this.submitting = false;
            this.errorMsg = err?.error?.detail ?? "An error occured.";
          }
        );
    } else {
      this.errorMsg = "Please enter a valid email address"
    }



  }

}
