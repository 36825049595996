import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {
	@Input() gameData: RecapGameData;
	@Input() images: GameImage[] = [];

	@Output() imgEvent = new EventEmitter<GameImage[]>();
	
	nextIndex: number;
	currentIndex: number;
	prevIndex: number;
	desaturateIndex: number = 0;
	isRevModalOpen: boolean = false;
	loading: boolean = false;
	disableImgBtns: boolean = false;

	displayImages: GameImage[] = [];

	constructor(private recapService: RecapService) { }

	ngOnInit(): void {
		// console.log(this.gameData)
		if (this.images.length) this.incrementIdx();
	}

	incrementIdx() {
		if (this.currentIndex === undefined) this.currentIndex = 0;
		else this.currentIndex++;
		if (this.currentIndex > this.images.length - 1) this.currentIndex = 0;
		if (this.nextIndex === undefined) this.nextIndex = this.currentIndex + 1;
		else this.nextIndex++;
		if (this.prevIndex === undefined) {
			this.prevIndex = this.currentIndex - 1;
			if (this.prevIndex < 0) this.prevIndex = this.images.length - 1;
		}
		else this.prevIndex++;
		if (this.nextIndex > this.images.length - 1) this.nextIndex = 0;
		if (this.prevIndex > this.images.length - 1) this.prevIndex = 0;
		this.changeDisplayImages()

	}

	decrementIdx() {
		this.currentIndex--;
		this.nextIndex--;
		this.prevIndex--;
		if (this.currentIndex < 0) this.currentIndex = this.images.length - 1;
		if (this.nextIndex < 0) this.nextIndex = this.images.length - 1;
		if (this.prevIndex < 0) this.prevIndex = this.images.length - 1;
		this.changeDisplayImages()
	}

	changeDisplayImages() {
		this.displayImages = [
			this.images[this.prevIndex],
			this.images[this.currentIndex],
			this.images[this.nextIndex]
		];
	}

	imageReaction(reaction: string) {
		this.disableImgBtns = true;
		this.loading = true;
		reaction = reaction === this.images[this.currentIndex].rm_reaction ? "" : reaction;
		this.recapService.imageReaction(this.gameData.gameid, this.images[this.currentIndex].image_code, reaction)
			.pipe(finalize(() => { this.disableImgBtns = false; this.loading = false; }))
			.subscribe(
				(res: GameImage[]) => {
					this.images[this.currentIndex].rm_reaction = res[0].rm_reaction;
					this.isRevModalOpen = false;
				},
				err => console.log(err.error.detail))
	}

	removeImage() {
		this.loading = true;
		this.recapService.setImageApproval(this.gameData.gameid, this.images[this.currentIndex].image_code, false)
    		.pipe(finalize(() => this.loading = false))
    		.subscribe((res: GameImage[]) => {
        		this.isRevModalOpen = false
        		this.imgEvent.emit(res);
				if (this.images.length) this.incrementIdx();
      },
      err => {
        console.log(err.error.detail)
      })
	}
}