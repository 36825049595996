<div class="survey--survey-item">
	<div>
		<input type="checkbox" [(ngModel)]="survey.selected">
	</div>
	<div class="survey--survey-details">
		<h3 class="no-m">{{survey.question_text}}</h3>
		<p class="vert-start no-m">
			<span>{{survey.responses.length}}/{{survey.response_target}} Responses (skipped {{survey.skips.length}}
				times)</span>
			<span>Status: <span [ngClass]="status('class')">{{status()}}</span></span>
			<span *ngIf="survey.responses.length">Top Response(s): <em>{{topResponse()}}</em></span>
		</p>
	</div>
	<div class="vert-between">
		<button class="basic-btn-red std-mb" (click)="activeModal = 'edit'">Manage</button>
		<button class="basic-btn-grey" (click)="activeModal = 'delete'">Delete</button>
	</div>
</div>

<div class="modal-background" *ngIf="activeModal === 'edit'">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>Manage Survey Question</h2>
			<h2 class="no-m sm-px" (click)="activeModal = ''">X</h2>
		</div>
		<app-survey-edit *ngIf="!loading; else loadMsg" [survey]="survey"></app-survey-edit>
	</div>
</div>

<div class="modal-background" *ngIf="activeModal === 'delete'">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>Are you sure?</h2>
			<h2 class="no-m sm-px" (click)="activeModal = ''">X</h2>
		</div>
		<h4>You are about to delete the survey question '{{survey.question_text}}' along with its response data. This
			action is NOT undoable!</h4>
		<div class="survey--button-control">
			<button class="basic-btn-lgt-blue" (click)="activeModal = ''">No, Go Back</button>
			<button class="basic-btn-red" (click)="delete()">Yes, Delete It</button>
		</div>
	</div>
</div>

<ng-template #loadMsg>
	<div>
		<h3 class="center-text">Loading...</h3>
	</div>
</ng-template>