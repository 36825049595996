import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject = new BehaviorSubject<User>(null)
  user$: Observable<User>

  rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

  constructor(
    private http: HttpClient
  ) {
    this.user$ = this.userSubject.asObservable()
  }

  getToken(): string {
    return localStorage.getItem('auth_token');
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  getUserFromToken() {
    let token = this.getToken()
    return token ? this.parseJwt(token) : null;
  }

  setToken(token: string): void {
    if (!token) {
      localStorage.removeItem('auth_token');
    } else {
      localStorage.setItem('auth_token', token);
    }
  }

  setUser(user: User) {
    this.userSubject.next(user)
  }

  clearUser() {
    this.userSubject.next(null)
  }

  getUserToken(username: string, password: string, honeypot: string = "") {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('hp', honeypot);
    return this.http.post(this.rootUri + "/api/admin/token", body);
  }

  getUserDetail() {
    return this.http.get(this.rootUri + "/api/host/details");
  }

  updateUser(updater: any) {
    return this.http.post(this.rootUri + "/api/host/update", updater);
  }

  getCurrentVersion() {
    return this.http.get(`${this.rootUri}/api/version?r=${Math.random()}`)
  }

  requestResetPassword(email) {
    return this.http.post(this.rootUri + "/api/password-reset-request", {email, source: "admin"});
  }

  saveNewPassword(token, new_password){
    return this.http.post(this.rootUri + `/api/password-reset/${token}`, {new_password});
  }

}
