<div>
	<div class="wrong-answer-round--header" (click)="showQuestions = !showQuestions">
		<app-svg-up-arrow *ngIf="showQuestions" class="wrong-answer-round--arrow"></app-svg-up-arrow>
		<app-svg-down-arrow *ngIf="!showQuestions" class="wrong-answer-round--arrow"></app-svg-down-arrow>
		<h3>Round {{round.round_order + 1}}: {{round.title}} ({{round.questions.length}} questions)</h3>
	</div>
	<ng-container *ngIf="showQuestions">
		<app-wrong-answer-question *ngFor="let q of round.questions" [question]="q"></app-wrong-answer-question>
	</ng-container>
</div>
