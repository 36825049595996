import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

	url = "";

	constructor(private router: Router) { }

	ngOnInit(): void {
		let rpts = this.router.url.split("/");
		for (let i = 2; i < rpts.length; i++) {
			this.url = `${this.url}/${rpts[i]}`;
		}
	}

	navTo(route: string) {
		let routeParts = route.split("/");
		let navRoute = ["reports"];
		let newUrl = "";
		for (let p of routeParts) {
			if (p) {
				navRoute.push(p);
				newUrl = `${newUrl}/${p}`;
			}
		}
		this.url = newUrl;
		this.router.navigate(navRoute);
	}

}
