<div class="modal-background">
	<div *ngIf="!staticBackground" class="click-layer" (click)="emitCancel()"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">{{title}}</h2>
			<i *ngIf="!hideX" class="material-icons clear clickable" (click)="emitCancel()">clear</i>
		</div>
		<p *ngIf="message">{{message}}</p>
		<div *ngIf="template">
			<ng-container *ngTemplateOutlet="template; context: {refContext: context}"></ng-container>
		</div>
		<div class="hrz-end">
			<button *ngIf="cancel" class="basic-btn-grey md-mx" (click)="emitCancel()">{{cancel}}</button>
			<button [disabled]="disabled" *ngIf="action" class="basic-btn-red md-mx ml-10"
				(click)="emitAction()">{{action}}</button>
		</div>
	</div>
</div>