import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SurveyService } from '../services/survey.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

	@Input() survey: Survey;
	@Output() deleted: EventEmitter<string> = new EventEmitter()

	loading: boolean;
	err = "";

	activeModal = ""

	responses: GroupedResponse[] = [];

	surveyStates = [
		{ label: "Inactive", class: "", route: "deactivate", prev: "", next: "Start Survey" },
		{ label: "Active", class: "orange", route: "start", prev:" Deactivate", next: "End Survey" },
		{ label: "Completed", class: "green", route: "end", prev: "Reopen Survey", next: "Finalize Results" },
		{ label: "Final", class: "", route: "finalize", prev: "Un-finalize", next: "Mark Used" },
		{ label: "Used", class: "", route: "mark-used", prev: "Mark Unused", next: "" }
	]

	constructor(private surveyServ: SurveyService) { }

	ngOnInit(): void {
		this.responses = this.groupResponses()
	}

	status(type: string = "label") {
		let stateIndex = 0;
		if (this.survey.used) stateIndex = 4;
		else if (this.survey.finalized) stateIndex = 3;
		else if (this.survey.completed) stateIndex = 2;
		else if (this.survey.active) stateIndex = 1;
		return type === 'index' ? stateIndex : this.surveyStates[stateIndex][type]
	}

	groupResponses() {
		let grouped: GroupedResponse[] = [];
		for (let g of this.survey.groups) {
			grouped.push({
				group_text: g.group_text,
				group_code: g.group_code,
				responses: g.responses,
				num: 0
			})
		}
		for (let r of this.survey.responses) {
			let inGroup = false;
			for (let g of grouped) {
				if (g.responses.includes(r.response_text.toLowerCase())) {
					inGroup = true;
					g.num++;
					break;
				}
			}
			if (!inGroup) {
				grouped.push({
					group_text: r.response_text,
					group_code: "",
					responses: [r.response_text.toLowerCase()],
					num: 1
				})
			}
		}
		let out: GroupedResponse[] = []
		for (let g of grouped) {
			if (g.num) {
				g.percent = Math.round(g.num / this.survey.responses.length * 100);
				out.push(g)
			}
		}
		return out.sort((a, b) => {
			if (a.percent > b.percent) return -1;
			if (a.percent < b.percent) return 1;
			else return 0;
		})
	}

	topResponse() {
		if (!this.responses.length) return "";
		let topPercent = this.responses[0].percent;
		let top = `${this.responses[0].group_text} (${topPercent}%)`;
		for (let i = 1; i < this.responses.length; i++) {
			if (this.responses[i].percent === topPercent) {
				top = `${top} and ${this.responses[i].group_text} (${this.responses[i].percent}%)`;
			}
		}
		return top;
	}

	delete() {
		this.loading = true;
		this.err = '';
		this.surveyServ.delete(this.survey.surveyid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveySuccess) => {
					this.activeModal = '';
					this.deleted.emit(this.survey.surveyid);
				},
				err => this.err = err.error.detail)
	}

}
