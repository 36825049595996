import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  rootUri = environment.ktRootUri ?? ""
  private notificationsSubject = new BehaviorSubject<AdminNotification[]>([])
  private notificationIndexSubject = new BehaviorSubject<number>(null)
  notifications$: Observable<AdminNotification[]>
  notificationIndex$: Observable<number> = this.notificationIndexSubject.asObservable()

  constructor(
    private http: HttpClient
  ) {
    this.notifications$ = this.notificationsSubject.asObservable()

  }

  showModal(index: number) {
    this.notificationIndexSubject.next(index)
  }

  getByDate(filterBy: string, from: string, to: string): Observable<AdminNotification[]> {
    const params: any = { filter_by: filterBy }
    params.date_from = from ?? ''
    params.date_to = to ?? ''

    return this.http.get<AdminNotification[]>(`${this.rootUri}/api/admin/notifications`, { params })
  }

  addOne(notification: AdminNotification) {
    this.notificationsSubject.next([notification, ...this.notificationsSubject.getValue()])
  }

  populateNotifications(newNotifications: AdminNotification[]) {
    this.notificationsSubject.next(newNotifications);
  }

  updateNotification(notification: AdminNotification): Observable<AdminNotification> {

    return this.http.post<AdminNotification>(`${this.rootUri}/api/admin/notifications/${notification.notificationid}/update`, notification)
  }

  deleteNotification(id: string): Observable<any> {
    return this.http.delete(`${this.rootUri}/api/admin/notifications/${id}/delete`)
  }

  getUsersById(ids: string[]): Observable<UserNameAndId[]> {

    return this.http.post<UserNameAndId[]>(`${this.rootUri}/api/host/get-users`, ids)
  }

  saveNotification(notification: AdminNotification): Observable<AdminNotification> {

    return this.http.post<AdminNotification>(`${this.rootUri}/api/admin/notifications/create`, notification)
  }

  getSeener(notificationid: string): Observable<UserNameAndId[]>{
    return this.http.post<UserNameAndId[]>(`${this.rootUri}/api/host/get-notification-seener`, {notificationid})
  }
}
