import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-game-stub',
  templateUrl: './game-stub.component.html',
  styleUrls: ['./game-stub.component.css']
})
export class GameStubComponent implements OnInit {

	@Input() game: GameStub;

	activeModal = '';
	err = '';
	loading = false;

	domainAppend = environment.domainAppend;

	constructor(private router: Router) { }

	ngOnInit(): void {
		console.log(environment);
	}

	titleConvert(title: string) {
		if (title === 'trivia' || title === 'slam') {
			return (title === 'trivia') ? 'Pub Quiz' : 'Survey Slam';
		}
		return title;
	}

	formatDate() {
    // Create a new Date object of the game
    const d = new Date(this.game.date);

    // options for formatting
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone: this.game.venue_tz || undefined,
    };
    // return formatted the date
    return d.toLocaleString('en-US', options);
  }

}

