import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SurveyService } from '../services/survey.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-survey-new',
  templateUrl: './survey-new.component.html',
  styleUrls: ['./survey-new.component.css']
})
export class SurveyNewComponent implements OnInit {

	@Input() newSurvey: NewSurvey;
	@Input() importedResponses: {incorrect: string, times_used: number}[] = [];

	@Output() cancelNew: EventEmitter<null> = new EventEmitter();
	@Output() createNew: EventEmitter<Survey> = new EventEmitter();

	addOptions: boolean;

	optionList: {option: string}[] = [];

	loading: boolean;
	err: string = "";

	constructor(private surveyServ: SurveyService) { }

	ngOnInit(): void {
		if (!this.newSurvey) this.resetSurvey();
		if (this.newSurvey.options.length) this.addOptions = true;
		if (this.importedResponses.length) this.prepImportResponses();
	}

	resetSurvey() {
		this.newSurvey = {
			question_text: "",
			response_target: 100,
			completed: null,
			options: [],
			responses: [],
			import_id: ""
		}
	}

	prepImportResponses() {
		let responses = [];
		for (let inc of this.importedResponses) {
			for (let i = inc.times_used; i > 0; i--) {
				responses.push({
					playerid: "",
					client_id: "",
					response_text: inc.incorrect,
					submit_time: this.newSurvey.completed
				})
			}
		}
		this.newSurvey.responses = responses;
		this.newSurvey.response_target = responses.length;
	}

	newOption() {
		this.optionList.push({option: ""});
	}

	removeOption(index: number) {
		this.optionList.splice(index, 1);
	}

	cleanOptions() {
		let opStrings: string[] = [];
		for (let o of this.optionList) {
			if (o) opStrings.push(o.option);
		}
		return opStrings;
	}

	cancel() {
		this.cancelNew.emit();
	}

	create() {
		console.log(this.newSurvey);
		this.newSurvey.options = this.addOptions ? this.newSurvey.options : [];
		this.surveyServ.create(this.newSurvey)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: Survey) => this.createNew.emit(res),
				err => this.err = err.error.detail)
	}

}
