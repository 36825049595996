<div>
	<app-resizing-textarea [initial]="newSurvey.question_text" [style]="'h3'" (changed)="newSurvey.question_text = $event"></app-resizing-textarea>
	<app-checkbox-input *ngIf="!importedResponses.length" [checked]="addOptions" [label]="'Multiple Choice'" (checkChanged)="addOptions = $event"></app-checkbox-input>
	<app-survey-options *ngIf="addOptions" [options]="newSurvey.options" (updatedOptions)="newSurvey.options = $event"></app-survey-options>
	<h3 *ngIf="!importedResponses.length">Num Responses: <input [(ngModel)]="newSurvey.response_target" type="number"></h3>
	<div *ngIf="importedResponses.length">
		<p><strong>Imported Respones</strong></p>
		<ul>
			<li *ngFor="let r of importedResponses">{{r.incorrect}} ({{r.times_used}} times)</li>
		</ul>
	</div>
</div>
<p *ngIf="err" class="error-message">{{err}}</p>
<div *ngIf="!loading" class="hrz-between">
	<button class="basic-btn-lgt-blue" (click)="cancel()">Cancel</button>
	<button class="basic-btn-red" [disabled]="!newSurvey.question_text" (click)="create()">Create</button>
</div>
