import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-resizing-textarea',
  templateUrl: './resizing-textarea.component.html',
  styleUrls: ['./resizing-textarea.component.css']
})
export class ResizingTextareaComponent implements OnInit {

	@Input() initial: string;
	@Input() style: string;
	@Input() canCancel: boolean
	@Input() canUpdate: boolean
	@Output() changed: EventEmitter<string> = new EventEmitter()
 	@Output() updated: EventEmitter<string> = new EventEmitter();
	@Output() canceled: EventEmitter<boolean> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
		if (!this.style) this.style = "p";
	}

	resizeTextArea($event: any) {
		$event.target.style.height = '0px';
		let scroll = $event.target.scrollHeight;
		$event.target.style.height = `${scroll}px`;
	}

	cancel() {
		this.canceled.emit(true)
	}

	update(value: string) {
		this.updated.emit(value)
	}

	change(value: string) {
		this.changed.emit(value)
	}

}
