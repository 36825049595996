import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByProp'
})
export class SearchByPropPipe implements PipeTransform {

  transform(value: any[], property: string = "", search: string = ""): Survey[] {
    if (!property || !search) return value;

    let out: Survey[] = []

    if (!value || !Array.isArray(value)) return [];

    for (let i of value) {
    	if (typeof i !== 'object' || i === null) continue;
    	if (!i[property] || typeof i[property] !== 'string') continue;
    	if (i[property].toLowerCase().includes(search.toLowerCase())) out.push(i);
    	else i.selected = false;
    }
    return out;
  }

}
