import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-players-by-venue-report',
  templateUrl: './players-by-venue-report.component.html',
  styleUrls: ['./players-by-venue-report.component.css']
})
export class PlayersByVenueReportComponent implements OnInit {

  venue: string = ''
  venueId: string = ''
  date_from: string = ''
  date_to: string = ''
  fetching: Boolean = false
  players: PlayersByVenue = null

  constructor(private reportService: ReportsService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.fetching = false
    }, 300);
  }

  getPlayers() {

    const f = new Date(this.date_from)
    const t = new Date(this.date_to)

    if (f > t || !this.date_from || !this.date_to) return

    this.fetching = true
    this.reportService.getPlayersByVenue(this.venueId, this.date_from, this.date_to)
      .pipe(finalize(() => this.fetching = false))
      .subscribe((result: PlayersByVenue) => {
        this.players = result

      }, error => {
        console.log("An error occured:" + error);
        console.log(error);

      })

  }

  onTypeaheadSelected(result) {
    if (result.hasOwnProperty('name')) {
      this.venue = result['name']
    }
    if (result.hasOwnProperty('sid')) {
      this.venueId = result['sid']
    }

  }

  onTypeaheadCleared(itemType: string) {
    this.venue = ''
  }


  downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvFile);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

  exportTableToCSV(tableId) {
    const filename = `Players by Venue - ${this.venue} (${this.date_from} to ${this.date_to}).csv`;
    const table = document.getElementById(tableId) as HTMLTableElement;
    let csv = [];
    for (let i = 0; i < table.rows.length; i++) {
      const row = [];
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        const cellData = table.rows[i].cells[j].innerText;
        if (cellData.includes(',')) {
          row.push('"' + cellData.replace(/"/g, '""') + '"');
        } else {
          row.push(cellData);
        }
      }
      csv.push(row.join(','));
    }
    this.downloadCSV(csv.join('\n'), filename);
  }

}
