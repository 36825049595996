
<app-resizing-textarea *ngIf="editing === 'question_text'; else questionText"
	[initial]="survey.question_text"
	[style]="'h3'"
	[canCancel]="true"
	[canUpdate]="true"
	(updated)="edit($event)"
	(canceled)="editing = ''"></app-resizing-textarea>
<!-- <app-checkbox-input *ngIf="!survey.completed && editing !== 'options'" [checked]="addOptions" [label]="'Multiple Choice'" (checkChanged)="addOptions = $event"></app-checkbox-input> -->
<div *ngIf="editing === 'options'; else optionDisplay" class="survey-edit--options">
	<app-survey-options [options]="survey.options" (updatedOptions)="options = $event"></app-survey-options>
	<div>
		<i class="material-icons" (click)="updateOptions()">done</i>
		<i class="material-icons" (click)="editing = ''">cancel</i>
	</div>
</div>
<h3>Status: <span [ngClass]="status('class')">{{status()}}</span></h3>
<div class="survey--response-box">
	<div *ngIf="editing === 'response_target'; else responseTarget" class="survey--response-target-control">
		<span>Responses ({{survey.responses.length}}/<input #rTarget type="number" [value]="survey.response_target">)</span>
		<i class="material-icons" (click)="edit(rTarget.value)">done</i>
		<i class="material-icons" (click)="editing = ''">cancel</i>
	</div>
	<ul>
		<li *ngFor="let r of responses">
			<div class="survey--response-line" (click)="clearIfNoneChecked()">
				<div *ngIf="!survey.finalized" class="survey--check-box">
					<input *ngIf="!r.group_code" type="checkbox" [(ngModel)]="r.checked">
					<div class="check-cover" (click)="r.checked = !r.checked"></div>
				</div>
				<span class="sm-mr" *ngIf="r.group_code" (click)="r.expanded = !r.expanded">{{r.expanded ? '(-)' : '(+)'}}</span>
				<div class="survey--response-text">
					<span *ngIf="r.group_code" (click)="r.expanded = !r.expanded">{{r.group_text}} ({{r.percent}}%)</span>
					<span *ngIf="!r.group_code" (click)="r.checked = !r.checked"> {{r.group_text}} ({{r.percent}}%)</span>
				</div>
				<i *ngIf="r.group_code && !survey.finalized" class="material-icons" (click)="removeGroup(r.group_code)">clear</i>
			</div>
			<ul *ngIf="r.expanded" class="survey--group-breakdown">
				<li *ngFor="let gr of r.responses">
					<span>{{gr}}</span>
					<i *ngIf="!survey.finalized" class="material-icons" (click)="ungroupResponse(gr)">remove</i>
				</li>
			</ul>
		</li>
	</ul>
	<button *ngIf="!choosingGroup && getSelected().length" (click)="startGrouping()" class="basic-btn-lgt-blue w-100">Group Responses</button>
	<div *ngIf="choosingGroup" class="survey--group-chooser">
		<div class="survey--group-select">
			<select [(ngModel)]="chosenGroup" class="w-100">
				<option value="">+ New Group</option>
				<option *ngFor="let g of getGroups()" [value]="g.group_code">{{g.group_text}}</option>
			</select>
			<input #gName class="w-100" [ngClass]="chosenGroup ? 'hidden' : ''" type="text" placeholder="Group Name">
		</div>
		<i class="material-icons" (click)="chosenGroup ? addToGroup(chosenGroup) : newGroup(gName.value)">done</i>
		<i class="material-icons" (click)="choosingGroup = false">cancel</i>
	</div>
</div>
<div class="survey--status-control">
	<button *ngIf="status('index'); else emptyBtn" (click)="setPrevState()" class="basic-btn-lgt-blue">{{status('prev')}}</button>
	<button *ngIf="status('index') < surveyStates.length - 1" (click)="setNextState()" class="basic-btn-red">{{status('next')}}</button>
</div>

<ng-template #questionText>
	<h3 class="survey--preview-question-text">
		<span>{{survey.question_text}}</span>
		<i *ngIf="!survey.finalized" class="material-icons" (click)="editing = 'question_text'">create</i>
	</h3>
</ng-template>

<ng-template #optionDisplay>
	<div class="survey-edit--options">
		<div *ngIf="options.length">
			<h4 class="sm-mb">Multiple Choice Options</h4>
			<ul>
				<li *ngFor="let o of options">{{o}}</li>
			</ul>
		</div>
		<h4 *ngIf="!options.length">Free Response</h4>
		<i *ngIf="!survey.completed" class="material-icons" (click)="editing = 'options'">create</i>
	</div>
</ng-template>

<ng-template #responseTarget>
	<h4 class="survey--preview-response-target">
		<span>Responses ({{survey.responses.length}}/{{survey.response_target}})</span>
		<i *ngIf="!survey.finalized" class="material-icons" (click)="editing = 'response_target'">create</i>
	</h4>
</ng-template>

<ng-template #emptyBtn>
	<div></div>
</ng-template>