import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input() notification: AdminNotification
  @Input() index: number
  @Input() isLast: boolean = false


  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    var unique = this.notification.exceptions
    this.notification.exceptions = [...new Set(unique)]
  }

  /**
   * Formats a date string into a localized date format.
   *
   * @param date {string} The date string to format.
   * @returns {string} The formatted date string.
   */
  formatDate(date: string): string {
    let d = new Date(date)
    return d.toISOString().split('T')[0];
  }

  showManageModal(index: number) {
    this.notificationService.showModal(index)
  }


}
