<div class="auth-container">

	<div class="auth-box">
		<h2 class="no-mt">King Trivia Admin Login</h2>
		<p *ngIf="err" class="error-message">{{err}}</p>
		<label>Email</label>
		<input [disabled]="loading" type="email" placeholder="you@example.com" [(ngModel)]="username">
		<label>Password</label>
		<input [disabled]="loading" type="password" [(ngModel)]="password">
		<div class="hidden">
	      <label for="hp">HP:</label>
	      <input [(ngModel)]="honeypot" id="hp" type="text" value="">
	    </div>
		<button [disabled]="loading" type="submit" (click)="submitCredentials()">
			{{ loading ? 'Signing in...' : 'Submit' }}
		</button>
    <p class="center-text"><a href="/forgot-password">Forgot Password?</a></p>
	</div>

</div>
