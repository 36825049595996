import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  totalLiveGames: number = 0
  totalLiveShifts: number = 0
  totalTeams: number = 0
  totalPlayers: number = 0
  scheduledTaks: ScheduledTasks[] = []
  serverStats: ServerStats

  constructor(private reportService: ReportsService) {

  }

  ngOnInit(): void {
    this.populateGameOverviewStats()
  }

  populateGameOverviewStats() {
    this.getServerStats()
    this.getTotalLiveGames()
    this.getTotalTeams()
    this.getTotalPlayers()
    this.getScheduledTasks()
    this.getTotalLiveShifts()
  }

  getTotalLiveGames() {
    this.reportService.getNumberOfLiveGames().subscribe((result: number) => {
      this.totalLiveGames = result
    })
  }
  getTotalLiveShifts() {
    this.reportService.getNumberOfLiveShifts().subscribe((result: number) => {
      this.totalLiveShifts = result
    })
  }

  getTotalTeams() {
    this.reportService.getNumberOfTeams().subscribe((result: number) => {
      this.totalTeams = result
    })
  }

  getTotalPlayers() {
    this.reportService.getNumberOfPlayers().subscribe((result: number) => {
      this.totalPlayers = result
    })
  }

  getScheduledTasks() {
    this.reportService.getScheduledTasks().subscribe((result: ScheduledTasks[]) => this.scheduledTaks = result)
  }

  getServerStats() {
    this.reportService.getServerStats().subscribe((result: ServerStats) => this.serverStats = result)
  }

  localeDate(date: string): string {
    let d = new Date(date)
    d.setSeconds(0)
    return d.toLocaleString().split(":00").join(" ")
  }


  formatBytes(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const result = Math.floor(Math.log(bytes) / Math.log(1024))
    const i = result
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  getPercentage(used: number, total: number): number {
    const result = (used / total) * 100
    return parseFloat(result.toFixed(2))
  }

}
