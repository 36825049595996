import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RecapsService } from '../services/recaps.service';
import { AuthService } from '../services/auth.service';
import { TypeaheadComponent } from '../typeahead/typeahead.component';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';

@Component({
	selector: 'app-recaps',
	templateUrl: './recaps.component.html',
	styleUrls: ['./recaps.component.scss']
})
export class RecapsComponent implements OnInit, OnDestroy {
	@ViewChild(TypeaheadComponent) typeaheadComponent: TypeaheadComponent;
	private destroy$ = new Subject<void>();

	loading = true;
	recaps: GameIdWithDate[];
	user: User;
	dateType: string = "submit_date"
	fromDate: string;
	toDate: string;
	status: string = "need_attention";
	employeeId: string;
	venueId: string;
	gameType: string = "";
	selectedSortOrder = "asc";
	typeaheadItemType: string;
	kingdom: number = -1;
	// isDataAvailable: boolean = false;

	numRecapsShown: number = 0;
	numRecapsLoaded: number = 0;
	numRecapsTotal: number = 0;

	err: string = "";

	constructor(
		private auth: AuthService,
		private recapsServ: RecapsService, private utils: UtilsService) { }

	ngOnInit(): void {
		const now = new Date();
		this.fromDate = this.setMinimumDateFrom(now);
		this.toDate = now.toISOString().substring(0, 10);
		this.user = this.auth.getUserFromToken();
		this.getRecapsByDate();
	}

  setMinimumDateFrom(date: Date): string{

    const threeMonthsAgo = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() - 89
		);
    return threeMonthsAgo.toISOString().substring(0, 10);
  }

	ngOnDestroy() {
		// Make sure to complete the Subject to prevent memory leaks.
		this.destroy$.next();
		this.destroy$.complete();
	}

	getRecapsByDate() {
    this.err = "";
		console.log(this.formatDate(new Date(this.fromDate)))
		this.loading = true;
		this.numRecapsShown = 0;
		this.numRecapsLoaded = 0;
		this.recapsServ.getRecapsByDate(
			this.formatUtcDate(new Date(this.fromDate)),
			this.formatUtcDate(new Date(this.toDate))
		)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => { this.loading = false; })
			)
			.subscribe((recaps: GameIdWithDate[]) => {
				this.recaps = recaps;
				this.sortRecaps();
				// console.log(recaps)
				this.numRecapsShown = this.recaps.length > 2 ? 2 : this.recaps.length;
				this.numRecapsTotal = this.recaps.length;
			},
      err => {
        this.err = err.error.detail ?? "An error occured."
        console.log("error => ",err);

      }
    )
	}

	getFilteredRecaps() {
    this.err = "";

    const dateRange = this.utils.validateDateRange(this.fromDate, this.toDate);
    if (dateRange !== "valid") {
      this.err = dateRange;
      return
    }


		this.destroy$.next();
		this.loading = true;
		this.numRecapsShown = 0;
		this.numRecapsLoaded = 0;
		this.recapsServ.getRecapsByFilters(
			this.formatUtcDate(new Date(this.fromDate)),
			this.formatUtcDate(new Date(this.toDate)),
			this.dateType,
			this.status,
			this.venueId,
			this.employeeId,
			this.gameType
		)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => { this.loading = false; }))
			.subscribe((recaps: GameIdWithDate[]) => {

				let recapsClone = [...recaps]
				recapsClone = recapsClone.filter((item) => item.zone == this.kingdom)

				this.recaps = this.kingdom === -1 ? recaps : recapsClone;
				this.sortRecaps();
				this.numRecapsShown = this.recaps.length > 2 ? 2 : this.recaps.length;
				this.numRecapsTotal = this.recaps.length;
			},
      err => {
        this.err = err.error.detail ?? "An error occured."
        console.log("error => ",err);

      }
    )
	}

	onSortOrderChange() {
		this.sortRecaps();
	}

	sortRecaps() {
		const sortOrder = this.selectedSortOrder === 'asc' ? 1 : -1;

		this.recaps.sort((a: GameStub, b: GameStub) => {
			if (a.date > b.date) return -1 * sortOrder;
			else if (a.date < b.date) return 1 * sortOrder;
			return 0;
		})
	}

	onTypeaheadSelected(itemType: string, result: string) {
		if (itemType === "employee") this.employeeId = result;
		else if (itemType === 'venue') this.venueId = result;
	}

	onTypeaheadCleared(itemType: string) {
		if (itemType === 'employee') this.employeeId = undefined;
		else if (itemType === 'venue') this.venueId = undefined;
	}

	onFromDateChange(e: any) {
		this.fromDate = new Date(e.target.value).toISOString().substring(0, 10);
	}

	onToDateChange(e: any) {
    let date = new Date(e.target.value);
		this.toDate = date.toISOString().substring(0, 10);
	}

	private formatDate(date: Date, timeString: string = "T00:00:00"): string {
		const tzOffset = -date.getTimezoneOffset();
		const diff = tzOffset >= 0 ? '+' : '-';
		const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate()) +
			timeString +
			diff + pad(tzOffset / 60) +
			':' + pad(tzOffset % 60);
	}

	private formatUtcDate(date: Date): string {
		const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate());
	}

	handleScroll(e) {
		if (!this.loading && this.numRecapsShown < this.recaps.length) {
			let se = e.target;
			if (se.scrollTop + se.offsetHeight > se.scrollHeight - 1500) {
				this.numRecapsShown++;
			}
		}
	}

	registerLoadedRecap() {
		this.numRecapsLoaded++;
	}

	filterByKingdom() {
		let zone = this.kingdom
		console.log(this.recaps);

	}
}
