<div *ngIf="!loading; else loadMsg">
	<div class="image-list--approved-container">
		<div *ngFor="let i of [0,1,2,3,4,5,6,7,8]" class="image-list--approved">
			<div class="image-list--approved-wrapper">
				<img *ngIf="approved[i]" src={{approved[i].url}} class="{{approved[i].orientation}}">
				<div class="image-list--unapprove">
					<i *ngIf="approved[i]" (click)="unapproveImage(i)" class="material-icons">close</i>
				</div>
			</div>
		</div>
		<div *ngIf="!approved.length" class="image-list--instructions">
			<h3>Include up to 9 images from the list below in your recap.</h3>
		</div>
	</div>

	

	<div class="image-list--container">
		<div class="image-list--image-container" *ngFor="let img of submitted; let i = index">
			<div class="image-list--image-sub-container">
				<img class="{{img.orientation}}" src="{{img.url}}" (click)="focusImg = i">
			</div>
		</div>
		<div class="image-list--upload" (click)="imgSelect.click()">
			<div>
				<input class="hidden" type="file" accept="image/*" (change)="prepFileUpload($event)" multiple="multiple" #imgSelect>
				<i class="material-icons">upload</i>
				<span>Upload More Images</span>
			</div>
		</div>
	</div>
</div>

<div *ngIf="focusImg !== null" class="image-list--focus-container">
	<img src="{{submitted[focusImg].url}}" class="{{submitted[focusImg].orientation}}" [ngClass]="pendingBlock ? 'blur-image' : ''">
	<div *ngIf="!pendingBlock" class="image-list--minimize">
		<i class="material-icons" (click)="focusImg = null">close_fullscreen</i>
		<div class="image-list--approve-ctrl" *ngIf="approved.length < 9" (click)="approveImage(focusImg)">
			<i class="material-icons">task_alt</i>
			<span>Approve</span>
		</div>
	</div>
	<div *ngIf="!pendingBlock" class="image-list--report">
		<i class="material-icons" (click)="pendingBlock = true">report</i>
		<span (click)="pendingBlock = true">Report</span>
	</div>
	<div *ngIf="pendingBlock" class="image-list--block-overlay">
		<div *ngIf="loading; else reportForm">{{loading}}</div>
	</div>
</div>

<ng-template #loadMsg>
	<h2>{{loading}}</h2>
</ng-template>

<ng-template #reportForm>
	<h3>Reporting image as inapproriate</h3>
	<div class="image-list--form-row">
		<select [(ngModel)]="blockReason">
			<option selected value="">Select a reason</option>
			<option value="harrasment">Harrassing/threatening</option>
			<option value="discrimination">Discriminatory/hateful</option>
			<option value="violent">Violent/disturbing</option>
			<option value="vulgar">Vulgar/pornographic</option>
			<option value="other">Other</option>
		</select>
	</div>
	<div class="image-list--form-row">
		<input *ngIf="blockReason === 'other'" [(ngModel)]="otherReason" type="text" placeholder="Other reason...">
	</div>
	<div class="image-list--form-row">
		<input type="checkbox" [(ngModel)]="blockPlayer">
		<label>Block future content from this player</label>
	</div>
	<p *ngIf="err" class="error-message">{{err}}</p>
	<div class="image-list--form-row">
		<button class="recap-ctrl-red" (click)="cancelBlock()">Cancel</button>
		<button class="recap-ctrl-blue" (click)="blockImage(focusImg)" [disabled]="!blockReason || (blockReason === 'other' && !otherReason)">Submit</button>
	</div>
</ng-template>
