import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeaheadService {
	rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

  constructor(private http: HttpClient) { }

  search(itemType: string, searchTerm: string): Observable<TypeaheadOption[]> {
    return this.http.get<TypeaheadOption[]>(this.rootUri + `/api/typeahead/${itemType}?search-term=${searchTerm}`);
  }
}