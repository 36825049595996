import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SurveyService } from '../services/survey.service';
import { SurveyComponent } from '../survey/survey.component';
import { finalize } from 'rxjs/operators';
import { SearchByPropPipe } from '../search-by-prop.pipe';
import { CSVService } from '../services/csv.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {

	@ViewChildren(SurveyComponent) surveyViews: QueryList<SurveyComponent>;

	surveys: Survey[]

	loading = true;
	err = ""

	view = "status";

	status = "all-unused"

	dateType: string = "created"
	fromDate: string;
	toDate: string;

	search = ""
	loadingSearch: boolean;
	searchLoaded: boolean;

	activeModal: string;

	newSurvey: NewSurvey

	constructor(private surveyServ: SurveyService, private csv: CSVService) { }

	ngOnInit(): void {
		this.fetchSurveys()
	}

	fetchSurveys() {
		this.loading = true;
		this.err = ""
		switch(this.view) {
			case "date":
				this.surveysByDate();
				break;

			default:
				this.surveysByStatus(this.status);
		}
	}

	switchView(view: string) {
		if (this.view === view) return;
		this.dateType = "created";
		this.fromDate = undefined;
		this.toDate = undefined;
		this.search = ""
		this.loadingSearch = false;
		this.searchLoaded = false;
		this.view = view;
		if (this.view !== 'status') this.surveys = [];
		else this.fetchSurveys();
	}

	changeStatus(status: string) {
		this.status = status;
		this.fetchSurveys()
	}

	surveysByStatus(status: string) {
		this.surveyServ.getByStatus(status)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveyListSuccess) => this.surveys = res.surveys,
				err => this.err = err.error.detail)
	}

	surveysByDate() {
		this.surveyServ.getByDate(this.dateType, this.fromDate, this.toDate)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: SurveyListSuccess) => this.surveys = res.surveys,
				err => this.err = err.error.detail)
	}

	updateSearch(search: string) {
		this.search = search;
		if (this.search.length < 3) {
			this.surveys = [];
			this.searchLoaded = false;
			this.loadingSearch = false;
		}
		else if (!this.searchLoaded) {
			this.surveys.forEach(s => s.selected = false)
			if (!this.loadingSearch) {
				this.loadingSearch = true;
				this.surveyServ.searchSurveys(this.search)
					.pipe(finalize(() => this.loadingSearch = false))
					.subscribe(
						(res: SurveyListSuccess) => {
							this.searchLoaded = true;
							this.surveys = res.surveys
						},
						err => this.err = err.error.detail)
			}
		}
	}

	createNew() {
		this.cancelNew()
		this.fetchSurveys()
	}

	startNew() {
		this.activeModal = 'new'
	}

	cancelNew() {
		this.activeModal = ""
		this.newSurvey = null;
	}

	removeFromList(s_id: string) {
		this.surveys = this.surveys.filter((s) => s.surveyid !== s_id);
	}

	getSurveyViewName() {
		let view = this.view;
		switch (view) {
			case 'status':
				view = `${view}_${this.status}`;
				break;

			case 'date':
				let from = this.fromDate ? `_${this.fromDate}` : "";
				let to = this.toDate ? `_${this.toDate}` : "";
				view = `${view}_${this.dateType}${from}${to}`;
				break;

			default:
				view = `${view}_${this.search}`
		}
		return view;
	}

	downloadView() {
		let fname = `surveys_by_${this.getSurveyViewName()}`
		let inView = [];
		this.surveyViews.forEach(s => inView.push(s))
		this.buildCSV(fname, inView)
	}

	downloadSelected() {
		let fname = `surveys_by_${this.getSurveyViewName()}`
		let selected = this.surveyViews.filter((s) => s.survey.selected)
		this.buildCSV(fname, selected)
	}

	buildCSV(filename: string, surveys: SurveyComponent[]) {
		let data = "question,response,num,percentage";
		for (let s of surveys) {
			let qt = s.survey.question_text;
			if (!s.responses.length) {
				data = `${data}\n${qt}`;
			}
			else for (let r of s.responses) {
				data = `${data}\n${qt},${r.group_text},${r.num},${r.percent}%`
			}
		}
		this.csv.export(data, filename);
	}

}
