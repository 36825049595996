import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecapsService {

	rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

  constructor(
    private http: HttpClient
  ) {}

  getRecapsByDate(frm: string, to: string) {
    return this.http.get(this.rootUri + "/api/recap/admin/list", {params: {frm, to}});
  }
  getRecapsByFilters(frm: string, to: string, date_type: string, status: string, venueid: string, userid: string, game_type: string) {
    const params: any = {};

    if (frm) {
      params.frm = frm;
    }
    if (to) {
      params.to = to;
    }
    if (date_type) {
      params.date_type = date_type;
    }
    if (status) {
      params.status = status;
    }
    if (venueid) {
      params.venueid = venueid;
    }
    if (userid) {
      params.userid = userid;
    }
    if (game_type) {
      params.game_type = game_type;
    }

    return this.http.get(this.rootUri + "/api/recap/admin/list", { params });
  }
}