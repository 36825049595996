import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-single-recap-viewer',
  templateUrl: './single-recap-viewer.component.html',
  styleUrls: ['./single-recap-viewer.component.css']
})
export class SingleRecapViewerComponent implements OnInit {

	stub: GameStub;
	user: User;

	constructor(private route: ActivatedRoute, private auth: AuthService) { }

	ngOnInit(): void {
		let gid = this.route.snapshot.paramMap.get('gameid')
		this.user = this.auth.getUserFromToken();
		this.stub = {
			kt_id: 0,
			gameid: gid,
			title: "",
			date: "",
			venue: "",
			status: "",
			zone: 0
		};
	}

}
