import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-edit',
  templateUrl: './svg-edit.component.html',
  styleUrls: ['./svg-edit.component.css']
})
export class SvgEditComponent implements OnInit {

	@Input() fill: string = "#000";

	constructor() { }

	ngOnInit(): void {
	}

}
