import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-survey-options',
  templateUrl: './survey-options.component.html',
  styleUrls: ['./survey-options.component.css']
})
export class SurveyOptionsComponent implements OnInit {

	@Input() options: string[] = [];

	@Output() updatedOptions: EventEmitter<string[]> = new EventEmitter();

	optionList: {option: string}[] = [];
  
	constructor() { }

	ngOnInit(): void {
		if (this.options.length) {
			for (let o of this.options) {
				this.addOption(o);
			}
		}
	}

	updateOptions() {
		let opts: string[] = [];
		for (let o of this.optionList) {
			if (o.option) opts.push(o.option);
		}
		this.updatedOptions.emit(opts);
	}

	addOption(opt: string = "") {
		console.log(this.optionList);
		this.optionList.push({option: opt});
		this.updateOptions();
	}

	removeOption(index: number) {
		this.optionList.splice(index, 1);
		this.updateOptions();
	}

}
