<div class="reports--master-container">
	<h1 class="center-text">Report Center</h1>
	<div *ngIf="!url; else closeBtn" class="center-text">
		<h2 class="center-text">Select a Report</h2>
		<select class="reports--report-select" (change)="navTo($event.target.value)">
			<option value=""></option>
			<option value="content/wrong-answer">Wrong Answer Report</option>
			<option value="content/players-by-venue">Players By Venue Report</option>
		</select>
	</div>

	<div class="reports--report-container">
		<router-outlet></router-outlet>
	</div>
</div>

<ng-template #closeBtn>
	<button (click)="navTo('')" class="sm-btn-red">Change Reports</button>
</ng-template>