import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TypeaheadService } from '../services/typeahead.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-typeahead',
	templateUrl: './typeahead.component.html',
	styleUrls: ['./typeahead.component.scss']
})
export class TypeaheadComponent {
	@Input() itemTypeID: string;
	@Input() clearOnItemSelect: boolean = false;
	@Output() itemSelected = new EventEmitter<TypeaheadOption>();
	@Output() clearSelected = new EventEmitter<boolean>();

	searchResults: TypeaheadOption[] = [];

	$searchSubscription: Subscription;

	fetchingDataBool: boolean = false;

	inputValue: string = '';
	selectedItem: TypeaheadOption;

	constructor(private typeaheadService: TypeaheadService) { }

	search(itemType: string, searchTerm: string) {
		if (searchTerm.length > 2) {
			if (!this.fetchingDataBool) {
				if (!this.searchResults.length) {
					this.fetchingDataBool = true;
					this.$searchSubscription = this.typeaheadService.search(itemType, searchTerm)
						.pipe(
							debounceTime(300),
							distinctUntilChanged()
						)
						.subscribe((results: TypeaheadOption[]) => {
							this.searchResults = results;
							this.fetchingDataBool = false;
						})
				}
			}
		} else {
			if (this.fetchingDataBool) {
				this.fetchingDataBool = false;
				this.$searchSubscription.unsubscribe();
			}
			this.searchResults = [];
		}
	}

	selectItem(item: TypeaheadOption) {
		const { name } = item
		this.itemSelected.emit(item);
		this.selectedItem = item;
		this.inputValue = this.clearOnItemSelect ? '' : name;
		this.searchResults = [];


	}

	handleKeyDownEvent(event: any) {
		if (this.selectedItem) {
			this.selectedItem = undefined;
			this.inputValue = "";
			this.clearSelected.emit(true);
		}
	}
}