import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  validateDateRange(startDate, endDate) {

    if (!startDate || !endDate) {
      return "Start and End date are required.";
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end.getTime() - start.getTime();

    // Convert time difference to days
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    // Check if the difference is greater than 90 days
    if (dayDifference > 90) {
      return "Please select a date range of 90 days or less.";
    }

    return "valid";
  }

}
