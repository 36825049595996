import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-up-arrow',
  templateUrl: './svg-up-arrow.component.html',
  styleUrls: ['./svg-up-arrow.component.css']
})
export class SvgUpArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
