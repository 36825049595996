import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

	rootUri = (environment.ktRootUri) ? environment.ktRootUri : "";

	constructor(
		private http: HttpClient
	) { }

	getByStatus(status: string) {
		return this.http.get(`${this.rootUri}/api/survey/status/${status}`)
	}

	getByImportId(import_id: number) {
		return this.http.get(`${this.rootUri}/api/survey/by-import/${import_id}`)
	}

	getByDate(searchby: string, frm: string, to: string) {
		let params: any = {searchby}
		if (frm) params.frm = frm;
		if (to) params.to = to;
		return this.http.get(`${this.rootUri}/api/survey/by-date`, {params})
	}

	searchSurveys(search: string) {
		return this.http.get(`${this.rootUri}/api/survey/search`, {params: {search}})
	}

	updateSurvey(s_id: string, props: any) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/edit`, props)
	}

	newGroup(s_id: string, group_text: string, responses: string[]) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/group/create`, {group_text, responses})
	}

	addToGroup(s_id: string, group_code: string, responses: string[]) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/group/${group_code}/responses`, {responses})
	}

	removeGroup(s_id: string, group_code: string) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/group/${group_code}/remove`, {})
	}

	ungroupResponses(s_id: string, responses: string[]) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/ungroup/responses`, {responses})
	}

	setStatus(s_id: string, status: string) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/${status}`, {})
	}

	delete(s_id: string) {
		return this.http.post(`${this.rootUri}/api/survey/${s_id}/delete`, {})
	}

	create(options: NewSurvey) {
		return this.http.post(`${this.rootUri}/api/survey/new`, options)
	}
}
