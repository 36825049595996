import { Component, Input, OnInit } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators'; 

@Component({
  selector: 'app-recap-office-notes',
  templateUrl: './recap-office-notes.component.html',
  styleUrls: ['./recap-office-notes.component.scss']
})
export class RecapOfficeNotesComponent implements OnInit {
  @Input() notes: OfficeNote;
  @Input() shiftData: RecapShiftData;
  @Input() user: User;
  editNoteBool: boolean;
  loading: boolean = false;
  notesLoading: boolean = false;
  officeNote: string = '';
  officeNoteEdit: string = '';
  noteAddedDate: string;
  noteAddedTime: string;

  constructor(
		private auth: AuthService,
    private recapServ: RecapService
  ) { }

  ngOnInit(): void {
    const date = new Date(this.notes.timestamp);
    this.noteAddedDate = this.formatDate(date);
    this.noteAddedTime = this.formatTime(date);
    this.user = this.auth.getUserFromToken();
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' });
  }

  formatTime(date: Date): string {
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  }

  activateNoteEdit(note: string) {
    this.officeNoteEdit = note;
    this.editNoteBool = true;
  }

  deactivateNoteEdit() {
    this.editNoteBool = false;
  }

  editOfficeNote(noteId: string) {
    this.notesLoading = true;
    this.recapServ.editOfficeNote(this.shiftData.shiftid, noteId, this.officeNoteEdit)
    	.pipe(finalize(() => this.notesLoading = false))
      .subscribe((res: RecapShiftData) => {
        this.officeNoteEdit = "";
        this.shiftData.office_notes = res.office_notes;
        this.editNoteBool = false;
      }) 
  }

  deleteOfficeNote(noteId: string) {
    this.notesLoading = true;
    this.recapServ.deleteOfficeNote(this.shiftData.shiftid, noteId)
    	.pipe(finalize(() => this.notesLoading = false))
      .subscribe((res: RecapShiftData) => {
        console.log(res);
        this.shiftData.office_notes = res.office_notes;
      }) 
  }
}
