<div>
	<ul class="survey-new-option--list">
		<li *ngFor="let o of optionList; let i = index">
			<input type="text" [(ngModel)]="optionList[i].option" placeholder="Multiple Choice option..." (focusout)=updateOptions()>
			<app-svg-trash (click)="removeOption(i)" class="survey-new-option--trash"></app-svg-trash>
		</li>
		<li>
			<button (click)="addOption('')">Add Option</button>
		</li>
	</ul>
</div>
