<div class="game-list-item">
	<div class="game-info">
		<h3>{{titleConvert(game.title)}} at {{game.venue}}</h3>
		<p>{{formatDate()}}</p>
		<p>Status: <span [ngClass]="{live: game.status === 'live' || game.status === 'recap missing' || game.status === 'recap needs review', scheduled: game.status === 'scheduled' || game.status === 'recap awaiting approval', warning: game.status === 'recap draft'}">{{game.status.toUpperCase()}}</span></p>
		<p *ngIf="game.hosts && game.hosts.length" class="no-m">
			<strong>Hosted By:</strong>
			<span *ngFor="let h of game.hosts; let i = index" class="md-ml">
				<span *ngIf="i > 0 && game.hosts.length > 2">,</span>
				<span *ngIf="i === game.hosts.length - 1 && game.hosts.length > 1"> and </span>
				<span>{{h.name}}</span>
			</span>
		</p>
	</div>
	<div *ngIf="!loading" class="vert-center">
		<a 
			*ngIf="game.status !== 'live' && game.status !== 'scheduled'" 
			href="https://{{domainAppend}}admin.kingtrivia.com/recaps/{{game.gameid}}">
			<button class="basic-btn-lgt-blue">Open Recap</button>
		</a>
		<a
			*ngIf="game.status !== 'scheduled' && game.status !== 'finalized'"
			href="https://{{domainAppend}}host.kingtrivia.com/game/{{game.gameid}}/live"
			target="_blank">
			<button class="basic-btn-red">Open Game</button>
		</a>
	</div>
	<div *ngIf="loading" class="vert-center">
		<span class="center-text">Confirming...</span>
	</div>
</div>
