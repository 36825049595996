<div id="dashboard-container">
    <div class="status-container">
        <div class="header">
            <div class="flex justify-between border-bottom-1">
                <p>Server Status</p>
                <!-- <a routerLink="/historical-data">History</a> -->
            </div>
        </div>
        <div class="body" *ngIf="serverStats else serverStatusLoader">
            <app-circular-progress-bar topLabel="RAM usage"
                [bottomLabel]="formatBytes(serverStats.ram_used) +' / '+ formatBytes(serverStats.ram_total)"
                [finalValue]="getPercentage(serverStats.ram_used, serverStats.ram_total)"></app-circular-progress-bar>
            <!-- <app-circular-progress-bar topLabel="CPU usage" [bottomLabel]="serverStats.cpu_cores + ' Core(s)'"
                [finalValue]="serverStats.cpu_usage"></app-circular-progress-bar>
            <app-circular-progress-bar topLabel="Disk usage"
                [bottomLabel]="formatBytes(serverStats.disk_used) +' / '+ formatBytes(serverStats.disk_total)"
                [finalValue]="getPercentage(serverStats.disk_used, serverStats.disk_total)"></app-circular-progress-bar> -->
            <app-circular-progress-bar topLabel="Redis connections"
                [bottomLabel]="serverStats.redis_connected_clients.toLocaleString() +' / '+ serverStats.redis_max_connection.toLocaleString()"
                [finalValue]="getPercentage(serverStats.redis_connected_clients, serverStats.redis_max_connection)"></app-circular-progress-bar>
        </div>
    </div>

    <div class="status-container">
        <div class="header">
            <p class="border-bottom-1">Game Overview</p>
        </div>
        <div class="body">
            <app-stats-card label="Live Games" [value]="totalLiveGames"></app-stats-card>
            <app-stats-card label="Live Shifts" [value]="totalLiveShifts"></app-stats-card>
            <app-stats-card label="Teams" [value]="totalTeams"></app-stats-card>
            <app-stats-card label="Players" [value]="totalPlayers"></app-stats-card>
        </div>
    </div>


    <div class="status-container" id="scheduled-task-container">
        <div class="header">
            <p class="border-bottom-1">Scheduled Tasks</p>
        </div>
        <div class="body">
            <table id="scheduled-task">
                <thead>
                    <tr>
                        <th>Task Name</th>
                        <th>Last Run</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="scheduledTaks.length > 0 else scheduleTaskLoader">
                        <tr *ngFor="let task of scheduledTaks">
                            <td>{{ task.task_name }}</td>
                            <td>{{ localeDate(task.last_run) }}</td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>

</div>

<ng-template #serverStatusLoader>
    <app-circular-progress-bar *ngFor="let i of [1,2,3,4]" [finalValue]="0" topLabel="..."
        bottomLabel="Calculating..."></app-circular-progress-bar>
</ng-template>

<ng-template #scheduleTaskLoader>
    <tr class="" *ngFor="let i of [1,2,3]">
        <td>
            <p class="p-5 skeleton">&nbsp;</p>
        </td>
        <td>
            <p class="p-5 skeleton">&nbsp;</p>
        </td>
    </tr>
</ng-template>