<div class="quizmaster">
  <div class="qm__type-badge type--no-show" *ngIf="showDidNotHappen">Show did not happen</div>
  <div class="quizmaster__info">
    <div class="info__col-name">
      <h4>Name</h4>
      <h3>{{ shiftHostData["first_name"] }} {{ shiftHostData["last_name"] }}</h3>
    </div>
    <div class="info__col-role">
      <h4>Role</h4>
      <h3>Quizmaster</h3>
    </div>
    <div class="info__col">
      <h4>Questions Rating</h4>
      <p>{{ questionRating }}</p>
    </div>
    <div class="info__col-badge">
      <div *ngIf="shiftData.status !== 'approved'" class="info__show-happen">
        <label class="show__checkbox-container">
          <input type="checkbox" [checked]="showDidNotHappen">
          <span class="show__checkmark"></span>
        </label>
        <div *ngIf="showHappLoading === false" class="show__check-cover" (click)="toggleShowHappen()"></div>
        <span *ngIf="showHappLoading === false" class="show__not-happen-label">Show did NOT happen</span>
        <div *ngIf="showHappLoading === true" class="show__row">
          <app-svg-loading-dark class="show__loading-icon"></app-svg-loading-dark>
          Loading...
        </div>
      </div>
      <div class="info__status-badge info--approved" *ngIf="shiftData.status === 'approved'">Approved</div>
      <div class="info__status-badge info--pending" *ngIf="shiftData.status === 'submitted'">Awaiting Approval</div>
      <div class="info__status-badge info--review info--review--button" *ngIf="shiftData.status === 'review'"
        (click)="toggleDropdown()">
        Needs Review
        <app-svg-down-arrow class="review__down-icon" *ngIf="!isRevDropdownOpen"></app-svg-down-arrow>
        <app-svg-up-arrow class="review__up-icon" *ngIf="isRevDropdownOpen"></app-svg-up-arrow>
      </div>
      <div class="info__status-badge info--draft" *ngIf="shiftData.status === 'draft'">Draft</div>
      <div [ngClass]="{ 'show': isRevDropdownOpen }" class="dropdown-content">
        <p>{{ shiftData.blog_feedback }}</p>
      </div>
    </div>
  </div>
  <div class="quizmaster__timecard">
    <div class="timecard__hours">
      <div class="hours__header-row">
        <div class="hours__col-start">
          <h4>Start/End Time ({{ tz }})</h4>
        </div>
        <div class="hours__col-desc">
          <h4>Description</h4>
        </div>
        <div class="hours__col-hours">
          <h4>Hours</h4>
        </div>
      </div>
      <div class="hours__row" *ngFor="let timecard of shiftData.work;">
        <div class="hours__col-start">
          <p *ngIf="timecard.timeMissing">TIME MISSING</p>
          <div class="hours__times" *ngIf="!timecard.timeMissing"
            [ngClass]="(startTimeOver || endTimeOver) && 'hours--over'">
            {{ timecard.start_time_formatted }} - {{ timecard.end_time_formatted }}
            <div class="hours__tt-container" (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
              <app-basic-tooltip class="tooltip" [ngClass]="{'active': showTooltip}">
                <div class="tooltip__copy" *ngIf="startTimeOver && !endTimeOver">
                  <p>Shift started over expected start time.</p>
                  <p>Expected: {{ expStartTime }}<br />
                    Started: {{ shiftStartTime }}</p>
                </div>
                <div class="tooltip__copy" *ngIf="!startTimeOver && endTimeOver">
                  <p>Shift ended over expected end time.</p>
                  <p>Expected: {{ expEndTime }}<br />
                    Ended: {{ shiftEndTime }}</p>
                </div>
                <div class="tooltip__copy" *ngIf="startTimeOver && endTimeOver">
                  <p>Shift started and ended over expected times.</p>
                  <p>Expected Start: {{ expStartTime }}<br />
                    Started: {{ shiftStartTime }}</p>
                  <p>Expected End: {{ expEndTime }}<br />
                    Ended: {{ shiftEndTime }}</p>
                </div>
              </app-basic-tooltip>
              <app-svg-caution class="hours__caution-icon" *ngIf="startTimeOver || endTimeOver"></app-svg-caution>
            </div>
          </div>
        </div>
        <div class="hours__col-desc">
          <p>{{ timecard.description}}</p>
        </div>
        <div class="hours__col-hours">
          <p>{{ timecard.total_hours }}</p>
        </div>
      </div>
    </div>

    <div class="timecard__pay">
      <div class="pay__header-row">
        <div class="pay__col">
          <h4>Target Pay</h4>
          <div class="pay__row" *ngIf="!editPayBool">
            <p *ngIf="targetPayLoading === false">${{ targetPay }}</p>
            <app-svg-edit class="pay__edit-icon" *ngIf="shiftData.status !== 'approved' && !targetPayLoading"
              (click)="editPayBool = true"></app-svg-edit>
            <div *ngIf="targetPayLoading === true" class="pay__row">
              <app-svg-loading-dark class="pay__loading-icon"></app-svg-loading-dark>
              Loading...
            </div>
          </div>
          <div class="pay__row" *ngIf="editPayBool === true;">
            <input type="number" class="pay__target-input" [(ngModel)]="targetPay" (blur)="changeTargetPay()" />
          </div>
        </div>
        <div class="pay__col">
          <h4>Hourly Pay</h4>
          <p>{{ totalHours }} x {{ hourlyPay }} = ${{ totalHourlyPay }}</p>
        </div>
        <div class="pay__col">
          <div class="pay__bonus-header">
            <h4>Bonus Pay</h4>
            <label *ngIf="shiftData.status !== 'approved'" class="pay__checkbox-container">
              <input type="checkbox" [checked]="bonusBool">
              <span class="pay__checkmark"></span>
            </label>
            <div *ngIf="shiftData.status !== 'approved'" class="pay__check-cover" (click)="toggleBonus()"></div>
          </div>
          <p *ngIf="bonusLoading === false">${{ totalBonusPay }}</p>
          <div *ngIf="bonusLoading === true" class="pay__row">
            <app-svg-loading-dark class="pay__loading-icon"></app-svg-loading-dark>
            Loading...
          </div>
        </div>
        <div class="pay__col-total">
          <h4>Total Pay</h4>
          <p class="bold">${{ totalPay }}</p>
        </div>
      </div>
    </div>

    <div class="timecard__notes">
      <h4>Internal Notes</h4>
      <p *ngIf="shiftData.internal_notes !== ''">{{ shiftData.internal_notes }}</p>
      <p *ngIf="shiftData.internal_notes === ''">No notes provided</p>
    </div>
    <div class="timecard__office-notes">
      <div class="hrz-start center-align-items">
        <h4 class="md-pr">Office Notes</h4>
        <button *ngIf="shiftData.status !== 'approved' && !addNotesBool" (click)="activateAddNotes()"
          class="sm-btn-red notes__add-btn">
          <app-svg-add class="notes__add-icon"></app-svg-add> Add
        </button>
      </div>
      <div class="notes__add-note" *ngIf="addNotesBool">
        <textarea class="notes__text-area" [(ngModel)]="officeNote"></textarea>
        <div class="notes__header-row">
          <button (click)="addOfficeNote()" class="sm-btn-red notes__save-btn" [disabled]="addNotesLoading">
            <span *ngIf="!addNotesLoading">Save Note</span>
            <app-svg-loading-light class="notes__loading-icon" *ngIf="addNotesLoading"></app-svg-loading-light>
          </button>
          <button (click)="cancelOfficeNote()" class="sm-btn-red m-5" [disabled]="addNotesLoading">Cancel</button>
        </div>
      </div>
      <div class="notes__list" *ngIf="shiftData.office_notes.length !== 0">
        <app-recap-office-notes class="notes__individual-note" *ngFor="let note of shiftData.office_notes"
          [notes]="note" [shiftData]="shiftData" [user]="user"></app-recap-office-notes>
      </div>
    </div>
    <!-- <div class="timecard__approval-buttons" *ngIf="shiftData.status !== 'approved'">
      <button 
        (click)="timecardApproved()" 
        [disabled]="revisionLoading || approvalLoading" 
        class="sm-btn-red">
        <span *ngIf="!approvalLoading">
          {{ shiftData.status === 'approved' ? 'Unapprove Timecard' : 'Approve Timecard' }}
        </span>
        <app-svg-loading-light class="approval__loading-icon" *ngIf="approvalLoading"></app-svg-loading-light>
      </button>
      <button 
        *ngIf="shiftData.status !== 'approved'" 
        (click)="shiftData.status === 'review' ? requiresRevision() : openRevisionModal()"
        [disabled]="approvalLoading || revisionLoading"
        class="sm-btn-red m-5">
        <span *ngIf="!revisionLoading || isRevModalOpen">
          {{ needsRevision ? 'Rescind Revision' : 'Requires Revision' }}
        </span>
        <app-svg-loading-light class="approval__loading-icon" *ngIf="revisionLoading && !isRevModalOpen"></app-svg-loading-light>
      </button>
    </div> -->
  </div>
</div>
<!-- 
<app-basic-modal 
	*ngIf="isRevModalOpen"
	[title]="'Please tell us why this needs a revision.'"
	[template]="revMsg"
	[hideX]="true"
	[staticBackground]="true"
	[action]="''"
	[cancel]="''">
</app-basic-modal>

<ng-template #revMsg>
	<div class="timecard__modal">
    <textarea class="timecard__modal-textarea" [(ngModel)]="blogFeedback"></textarea>
		<button (click)="requiresRevision()" class="sm-btn-red" [disabled]="revisionLoading">
      <span *ngIf="!revisionLoading">Submit</span>
      <app-svg-loading-light 
        class="approval__loading-icon" 
        *ngIf="revisionLoading"></app-svg-loading-light>
    </button>
    <button (click)="closeRevisionModal()" class="sm-btn-red m-5" [disabled]="revisionLoading">Cancel</button>
	</div>
</ng-template> -->