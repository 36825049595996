<div class="w-screen  flex gap-2 justify-between items-center " *ngFor="let i of repeatable">
    <div class="w-80 p-10">
        <div class="p-10 bg-gray-100 skeleton md-mb"></div>
        <div class="p-10 bg-gray-100 skeleton mb-5"></div>
        <div class="p-10 bg-gray-100 skeleton w-20"></div>
    </div>
    <div class="w-10 p-10">
        <div class="p-15 bg-gray-100 skeleton"></div>
    </div>
    <span class="p-2"></span>
</div>