<div class="wrong-answer-question--box">
	<p class="md-pr"><strong>{{question.question_order + 1}}.</strong></p>
	<div class="wrong-answer-question--container">
		<img *ngIf="question.image" src="{{question.image}}">
		<p class="sm-mb"><strong>{{question.question_text}}</strong></p>
		<p class="no-mt">
			<em>
				<span>Correct Answer(s): </span>
				<span *ngFor="let a of question.correct_answers; let i = index">{{i ? ', ' : ' '}}{{a}}</span>
			</em>
		</p>
		<h4 class="sm-mb">Incorrect Answers:</h4>
		<p class="wrong-answer-question--incorrect-preview">
			<span *ngFor="let inc of question.incorrect_answers; let i = index">{{i ? ', ' : ''}}{{inc.incorrect}} ({{inc.times_used}})</span>
		</p>
		<p *ngIf="err" class="error-message">{{err}}</p>
	</div>
	<div class="wrong-answer-question--control">
		<app-svg-loading-dark *ngIf="loading; else addSurvey" class="wrong-answer-question--svg"></app-svg-loading-dark>
	</div>
</div>

<ng-template #addSurvey>
	<button *ngIf="!question.survey; else alreadyUsed" class="new-survey" (click)="modal = 'new'">
		<app-svg-add [fill]="'#adadad'" class="wrong-answer-question--svg"></app-svg-add>
		<span>Make Survey</span>
	</button>
</ng-template>

<ng-template #alreadyUsed>
	<button class="existing-survey" (click)="modal = 'edit'">
		<app-svg-edit [fill]="'#41954c'" class="wrong-answer-question--svg"></app-svg-edit>
		<span>Manage Survey</span>
	</button>
</ng-template>

<app-basic-modal 
	*ngIf="modal === 'new'"
	[title]="'Create a Survey from Wrong Answers'"
	[template]="newSurvey"
	[cancel]="''"
	[context]="this"
	[hideX]="true"
	[staticBackground]="true"></app-basic-modal>


<ng-template #newSurvey>
	<app-survey-new 
		[newSurvey]="newSurveyTemplate"
		[importedResponses]="question.incorrect_answers"
		(cancelNew)="modal = ''"
		(createNew)="refContext.addSurvey($event)"></app-survey-new>
</ng-template>

<app-basic-modal 
	*ngIf="modal === 'edit'"
	[title]="'Manage Wrong Answer Survey'"
	[template]="editSurvey"
	[cancel]="''"
	(doCancel)="modal = ''"></app-basic-modal>


<ng-template #editSurvey>
	<app-survey-edit [survey]="question.survey"></app-survey-edit>
</ng-template>