<div class="notification-item" [ngClass]="isLast ? 'last-notification':''">
    <div class="flex items-center justify-between">
        <div>
            <h3 class="notification-title">{{ notification.title}}</h3>
            <p class="notification-description">{{ notification.body }}</p>

        </div>
        <button class="plain-button" (click)="showManageModal(index)">Manage</button>
    </div>
    <div class="flex justify-between">
        <small class="text-muted">
            <span>{{ notification.distribute_to_all ? 'Send to all':'Send only to ' }}</span>
            <span>{{ notification.distribute_to_all && notification.exceptions.length > 0 ? ' except ': '' }}</span>
            <span>{{ notification.exceptions.length > 0 ? notification.exceptions.length + ' user(s)' : ''
                }}</span>
            <span *ngIf="notification.distribute_starting || notification.distribute_until">. Send</span>
            <span *ngIf="notification.distribute_starting"> starting on {{
                formatDate(notification.distribute_starting ) }}</span>
            <span *ngIf="notification.distribute_until"> until {{ formatDate(notification.distribute_until )
                }}</span>
        </small>
        <small class="text-muted right-text">Created on {{ formatDate(notification.created_on.toString()) }}</small>
    </div>
</div>