import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ReportsService } from '../services/reports.service';

@Component({
  selector: 'app-historical-data',
  templateUrl: './historical-data.component.html',
  styleUrls: ['./historical-data.component.css']
})
export class HistoricalDataComponent implements OnInit {
  chart: Chart
  dashboardStatsHistory: StatsArchive[] = []
  // charts settings
  serverChart?: Chart = null
  serverCategories: string[] = []
  serverData: number[] = []
  serverSeries = []
  serverPlotOptSeries: object = {}

  constructor(private reportService: ReportsService) { }

  ngOnInit(): void {
    this.reportService.getHistoricalDashboardStats()
      .subscribe((records: StatsArchive[]) => {
        this.dashboardStatsHistory = records
        this.applyChart('daily')
      })
  }

  initServerChart() {
    this.serverChart = new Chart({
      chart: {
        type: 'area'
      },
      title: {
        text: 'Server Stats'
      },
      credits: {
        enabled: false
      },
      xAxis: {
        //categories: this.serverCategories,
        type: 'datetime'
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d, %A at %I:%M %p',
      },
      plotOptions: {
        series: this.serverPlotOptSeries
      },
      series: this.serverSeries
    });
  }

  applyChart(frequency: string = 'daily') {
    let ramData = []
    let cpuData = []
    let redisData = []
    let labels = []

    const ram = this.dashboardStatsHistory.filter((item) => item.type == "ram_usage")[0]
    const cpu = this.dashboardStatsHistory.filter((item) => item.type == "cpu_usage")[0]
    const redis = this.dashboardStatsHistory.filter((item) => item.type == "redis_usage")[0]

    switch (frequency) {
      case 'weekly':
        ramData = ram.weekly.map((item) => item.value)
        cpuData = cpu.weekly.map((item) => item.value)
        redisData = redis.weekly.map((item) => item.value)

        // labels = cpu.weekly.map((item) => {
        //   let d = new Date(item.timestamp)
        //   return d.toLocaleDateString()
        // })

        var s = new Date(cpu.daily[0].timestamp)

        this.serverPlotOptSeries = {
          pointStart: Date.UTC(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes()),
          pointInterval: 1440000,
          dateTimeLabelFormats: {
            hour: '%H:%M'
          },

        }
        break;
      case 'monthly':
        ramData = ram.monthly.map((item) => item.value)
        cpuData = cpu.monthly.map((item) => item.value)
        redisData = redis.monthly.map((item) => item.value)

        labels = cpu.monthly.map((item) => {
          let d = new Date(item.timestamp)
          return d.toLocaleDateString()
        })
      case 'yearly':
        ramData = ram.yearly.map((item) => item.value)
        cpuData = cpu.yearly.map((item) => item.value)
        redisData = redis.yearly.map((item) => item.value)

        labels = cpu.yearly.map((item) => {
          let d = new Date(item.timestamp)
          return d.toLocaleDateString()
        })
      default:
        ramData = ram.daily.map((item) => item.value)
        cpuData = cpu.daily.map((item) => item.value)
        redisData = redis.daily.map((item) => item.value)


        var s = new Date(cpu.daily[0].timestamp)

        this.serverPlotOptSeries = {
          pointStart: Date.UTC(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes()),
          pointInterval: 120000,
          dateTimeLabelFormats: {
            hour: '%H:%M'
          },

        }
        break;
    }


    this.serverCategories = labels

    // Add series data
    this.serverSeries.push(
      {
        name: 'RAM',
        type: "line",
        data: ramData,
        color: "#FFA500",
        tooltip: {
          valueSuffix: '{y} %'
        }
      }
    )

    this.serverSeries.push(
      {
        name: 'CPU',
        type: "line",
        data: cpuData,
        tooltip: {
          valueSuffix: '{y} %'
        }
      }
    )

    this.serverSeries.push(
      {
        name: 'Redis',
        type: "line",
        data: redisData,
        tooltip: {
          valueSuffix: '{y} %'
        }
      }
    )

    this.initServerChart()
  }

}
