<div *ngIf="recapGameData; else loadMsg" class="quizmaster-recap">

	<div class="recap__header">
		<div>
			<h1>{{ recapGameData.venue }}</h1>
			<h3 class="flex justify-between flex-col">
				<span>{{ gameDate }} &mdash; {{ expStartTime }} ({{ recapVenueTz }})</span>
				<span>{{ !recapGameData.zone ? 'No Kingdom assigned':'Kingdom ' + recapGameData.zone }}</span>
			</h3>
		</div>
		<div>
			<div class="recap__type-badge type--pq" *ngIf="recapGameData.title === 'trivia'">Pub Quiz</div>
			<div class="recap__type-badge type--ss" *ngIf="recapGameData.title === 'slam'">Survey Slam</div>
			<div class="recap__type-badge type--mb" *ngIf="recapGameData.title === 'bingo'">Music Bingo</div>
			<div class="recap__type-badge type--os"
				*ngIf="recapGameData.title !== 'trivia' && recapGameData.title !== 'slam' && recapGameData.title !== 'bingo'">
				{{recapGameData.title}}</div>
			<div class="recap__type-badge type--private" *ngIf="recapGameData.private_event">Private Event</div>
		</div>
	</div>

	<div *ngIf="err" class="recap-error-message">
		<span>{{err}}</span>
	</div>

	<div class="recap-bubble" *ngFor="let quizmaster of recapShiftsData; let i = index">
		<div class="hrz-between center-align-items recap-bubble-header">
			<div (click)="hideTimecard = !hideTimecard" class='hrz-start center-align-items clickable'>
				<i class="material-icons std-mr">{{hideTimecard ? 'chevron_right' : 'expand_more'}}</i>
				<h3 class="no-my std-mr">Timecard is {{quizmaster.status === 'approved' ? '' : 'NOT '}}APPROVED</h3>
				<h3 *ngIf="loading" class="no-my std-mr">{{recapGameData.status === 'approved' ? 'Una' :
					'A'}}pproving
					Timecard...</h3>
			</div>
			<div>
				<button
					(click)="quizmaster.status !== 'approved' ? timecardApproved(quizmaster) : timecardUnapproved(quizmaster)"
					[disabled]="quizmaster.loading" class="sm-btn-red">
					<span *ngIf="!quizmaster.loading">
						{{ quizmaster.status === 'approved' ? 'Unapprove ' : 'Approve' }}
					</span>
					<app-svg-loading-light class="approval__loading-icon"
						*ngIf="quizmaster.loading"></app-svg-loading-light>
				</button>
				<button *ngIf="quizmaster.status !== 'approved'"
					(click)="quizmaster.status === 'review' ? requiresRevision(quizmaster) : revisionTarget = quizmaster"
					[disabled]="quizmaster.loading" class="sm-btn-red m-5">
					<span *ngIf="!quizmaster.loading">
						{{ quizmaster.status === 'review' ? 'Rescind Revision' : 'Request Revision' }}
					</span>
					<app-svg-loading-light class="approval__loading-icon"
						*ngIf="quizmaster.loading"></app-svg-loading-light>
				</button>
			</div>
		</div>
		<app-recap-timecard (hideTimecardEvent)="timecardApprovedEvent($event)" [ngClass]="hideTimecard ? 'hidden' : ''"
			[gameData]="recapGameData" [shiftData]="quizmaster" [user]="user" [tz]="venueTz"
			class="timecard__container"></app-recap-timecard>
	</div>

	<div class="recap-bubble" [ngClass]="recapGameData.status === 'live' ? '' : 'hidden'">
		<h2 class="recap__game-live">GAME IS STILL LIVE</h2>
	</div>

	<div class="recap-bubble" *ngIf="isAnyItemActive()" [ngClass]="recapGameData.status === 'live' ? 'hidden' : ''">
		<div class="hrz-between center-align-items recap-bubble-header">
			<div (click)="hideRecap = !hideRecap" class='hrz-start center-align-items clickable'>
				<i class="material-icons std-mr">{{hideRecap ? 'chevron_right' : 'expand_more'}}</i>
				<h3 *ngIf="recapGameData.status !== 'finalized' && !loading" class="no-my std-mr">Recap is NOT
					PUBLISHED
					yet</h3>
				<h3 *ngIf="recapGameData.status === 'finalized' && !loading" class="no-my std-mr">Recap is PUBLISHED
				</h3>
				<h3 *ngIf="loading" class="no-my std-mr">{{recapGameData.status === 'finalized' ? 'Unp' :
					'P'}}ublishing
					Recap...</h3>
			</div>
			<button *ngIf="recapGameData.status !== 'finalized' && !loading" [disabled]="publishBlock"
				class="sm-btn-red" (click)="publishRecap(true)">Publish Recap</button>
			<button *ngIf="recapGameData.status === 'finalized' && !loading" class="sm-btn-red"
				(click)="publishRecap(false)">Unpublish Recap</button>
		</div>

		<div [ngClass]="hideRecap ? 'hidden' : ''">
			<div *ngIf="!loading" class="recap__game-stats std-p">
				<div class="stats__hours-col">
					<div class="stats__times-header">
						<h4>Start/End Time ({{ venueTz }})</h4>
						<div class="stats__tt-container" (mouseenter)="showTooltip = true"
							(mouseleave)="showTooltip = false">
							<app-basic-tooltip class="tooltip" [ngClass]="{'active': showTooltip}">
								<div class="tooltip__copy" *ngIf="startTimeOver">
									<p>Game started over expected start time.</p>
									<p>Expected: {{ expStartTime }}<br />
										Started: {{ gameStartTime }}</p>
								</div>
							</app-basic-tooltip>
							<app-svg-caution class="stats__caution-icon" *ngIf="startTimeOver"></app-svg-caution>
						</div>
					</div>
					<div *ngIf="recapGameData.status !== 'finalized'; else publishedTime" class="stats__input-row">
						<select (change)="checkHours($event.target.value)" [(ngModel)]="selectedTimeOption"
							[ngClass]="startTimeOver && 'stats__hours-over'">
							<option *ngFor="let option of recapShiftsData; let i = index"
								[selected]="selectedTimeOption === i.toString()" [value]="i.toString()">
								{{ option.user.name }}:
								<span
									*ngIf="recapShiftsData[i].game_start === null || recapShiftsData[i].game_end === null">TIME
									MISSING</span>
								<span
									*ngIf="recapShiftsData[i].game_start !== null && recapShiftsData[i].game_end !== null">{{
									recapShiftsData[i].qm_start_formatted }} - {{
									recapShiftsData[i].qm_end_formatted
									}}</span>
							</option>
							<option value="app">App: {{ recapGameData.game_start_formatted }} - {{
								recapGameData.game_end_formatted }}</option>
						</select>
					</div>
				</div>
				<div class="stats__col">
					<h4>Players Count</h4>
					<div *ngIf="recapGameData.status !== 'finalized'; else publishedPlayers" class="stats__input-row">
						<select [(ngModel)]="selectedPlayersOption">
							<option *ngFor="let option of recapShiftsData; let i = index"
								[selected]="selectedPlayersOption === i.toString()" [value]="i.toString()">
								{{ option.user.name }}:
								{{ recapShiftsData[i].total_players }}
							</option>
							<option value="app">App: {{ playerCount }}</option>
							<option value="custom">Custom</option>
						</select>
						<input type="text" class="stats__input-num" *ngIf="selectedPlayersOption === 'custom'"
							[(ngModel)]="customPlayerCount" />
					</div>
				</div>
				<div class="stats__col">
					<h4>Team Count</h4>
					<div *ngIf="recapGameData.status !== 'finalized'; else publishedTeams" class="stats__input-row">
						<select [(ngModel)]="selectedTeamsOption">
							<option *ngFor="let option of recapShiftsData; let i = index"
								[selected]="selectedTeamsOption === i.toString()" [value]="i.toString()">
								{{ option.user.name }}:
								{{ recapShiftsData[i].qm_teams }}
							</option>
							<option value="app">App: {{ teamCount }}</option>
							<option value="custom">Custom</option>
						</select>
						<input type="text" class="stats__input-num" *ngIf="selectedTeamsOption === 'custom'"
							[(ngModel)]="customTeamsCount" />
					</div>
				</div>
				<div class="stats__col">
					<h4>Empty Table Count</h4>
					<div *ngIf="recapGameData.status !== 'finalized'; else publishedEmpty" class="stats__input-row">
						<select [(ngModel)]="selectedEmptyOption">
							<option *ngFor="let option of recapShiftsData; let i = index"
								[selected]="selectedEmptyOption === i.toString()" [value]="i.toString()">
								{{ option.user.name }}:
								{{ recapShiftsData[i].empty_tables }}
							</option>
							<option value="custom">Custom</option>
						</select>
						<input type="text" class="stats__input-num" *ngIf="selectedEmptyOption === 'custom'"
							[(ngModel)]="customEmptyCount" />
					</div>
				</div>
			</div>

			<div *ngIf="!loading && !recapGameData.private_event" class="std-p">
				<app-recap-public [game]="recapGameData" [shifts]="recapShiftsData" [user]="user"
					(blogUpdate)="selectedBlogOption = $event" (imgEditing)="publishBlock = $event"></app-recap-public>
			</div>
		</div>

	</div>

</div>

<ng-template #publishedTime>
	<p [ngClass]="startTimeOver && 'stats__hours-over'">{{ adjustedStart }} - {{ adjustedEnd }}</p>
</ng-template>

<ng-template #publishedPlayers>
	<p>{{recapGameData.total_players}}</p>
</ng-template>

<ng-template #publishedTeams>
	<p>{{recapGameData.total_teams}}</p>
</ng-template>

<ng-template #publishedEmpty>
	<p>{{recapGameData.empty_tables}}</p>
</ng-template>

<ng-template #loadMsg>
	<!-- <h3 *ngIf="!err; else errMsg">Fetching recap data...</h3> -->
</ng-template>

<ng-template #errMsg>
	<div class="error-message">
		<p>{{err}}</p>
	</div>
</ng-template>

<app-basic-modal *ngIf="revisionTarget" [title]="'Please tell us why this needs a revision.'" [template]="revMsg"
	[hideX]="true" [staticBackground]="true" [action]="''" [cancel]="''">
</app-basic-modal>

<ng-template #revMsg>
	<div class="timecard__modal flex flex-col">
		<textarea class="timecard__modal-textarea" rows="5" [(ngModel)]="revisionTarget.blog_feedback"></textarea>
		<div class="flex justify-end">
			<button (click)="requiresRevision(revisionTarget)" class="sm-btn-red" [disabled]="revisionTarget.loading">
				<span *ngIf="!revisionTarget.loading">Submit</span>
				<app-svg-loading-light class="approval__loading-icon"
					*ngIf="revisionTarget.loading"></app-svg-loading-light>
			</button>
			<button (click)="closeRevisionModal()" class="sm-btn-red m-5"
				[disabled]="revisionTarget.loading">Cancel</button>
		</div>
	</div>
</ng-template>
